import React, { useEffect } from 'react'

import useFetch from 'hooks/use-fetch'
import { OFFER_MANAGEMENT_LIST, OFFER_MANAGEMENT_LIST_URL, OFFER_MANAGEMENT_SHOW } from 'api/offer-management'
import { Badge, Title } from 'rizzui'
import { Form, Modal, Select, TableColumnProps } from 'antd'
import moment from 'moment'
import TableComponents from 'components/Table'
import { Button, Card, CardBody, CardHeader, Divider, Tooltip } from '@nextui-org/react'
import Lottie from 'react-lottie'
import { getOrderStatusWithClass, getOrderWithAnimation, orderStatusText } from 'utils/get-order-status'
import cn from 'utils/class-names'
import { MoneyFormatTR } from 'hooks/currency'
import { USER_SHOW } from 'api/user'
import Left from './Left'
import Mail from './Mail'

const MailBox = () => {
   const [mailQuery, { data: mailData, loading, error: mailError }] = useFetch<any>()
   const [usersQuery, { data: usersData, loading: usersLoading }] = useFetch<any>()

   const users = JSON.parse(localStorage.getItem('user') as any)

   const columns: TableColumnProps<any>[] = [
      {
         title: 'Mail ID',
         dataIndex: 'mail_id',
         key: 'mail_i',
         render: (text: any) => <span>{text}</span>
      },
      {
         title: 'Kimden',
         dataIndex: 'mail_address',
         key: 'mail_address',
         render: (text: any) => <span>{text}</span>
      },
      {
         title: 'Kime',
         dataIndex: 'mail_from',
         key: 'mail_from',
         render: (text: any) => <span>{text}</span>
      },
      {
         title: 'Konu',
         dataIndex: 'mail_subject',
         key: 'mail_subject',
         render: (text: any) => <span>{text}</span>
      },
      {
         title: 'Detay',
         dataIndex: 'mail_body',
         key: 'id',
         render: (text: any) => (
            <span>
               <Button
                  color="primary"
                  variant="bordered"
                  size="sm"
                  onClick={() => {
                     Modal.info({
                        title: 'Detay',
                        content: <div dangerouslySetInnerHTML={{ __html: text }}></div>,
                        okText: 'Mail Atama Yap',
                        closable: true,
                        onCancel() {

                        },
                        cancelText: 'Kapat',
                        onOk() {
                           setModal(true)
                        }
                     })
                  }}
               >
                  Detay
               </Button>
            </span>
         )
      }
   ]

   const [modal, setModal] = React.useState(false)
   const [form] = Form.useForm()

   const onFinish = (values: any) => {

   }

   useEffect(() => {
      //@ts-ignore
      mailQuery(OFFER_MANAGEMENT_LIST, {
         endPoint: '/mails'
      })
   }, [])

   useEffect(() => {
      //@ts-ignore
      usersQuery(USER_SHOW, {
         endPoint: '/users-all'
      })
   }, [])
   //?id=1
   const id = window.location.search.split('=')[1]

   return (
      <div>
         <Modal title="Atama Yap" open={modal} footer={null} closable onCancel={() => setModal(false)}>
            <Form form={form} layout="vertical" name="form_in_modal" onFinish={onFinish}>
               <Form.Item name="user" label="Kullanıcı" rules={[{ required: true, message: 'Kullanıcı seçiniz!' }]}>
                  <Select placeholder="Seçim">
                     {usersData?.result?.map((user: any) => (
                        <Select.Option key={user.id} value={user.id}>
                           {user.name}
                        </Select.Option>
                     ))}
                  </Select>
               </Form.Item>
               <div className="gap-2 flex">
                  <Button type="submit" color="primary" isLoading={loading} onClick={form.submit}>
                     Atama Yap
                  </Button>
                  <Button color="danger" onClick={() => setModal(false)}>
                     İptal
                  </Button>
               </div>
            </Form>
         </Modal>
         <div className="@container">
            <Mail users={usersData} data={mailData?.data ?? []} id={id as any} />
         </div>
      </div>
   )
}

export default MailBox
