import axios from "axios";
import { FetchProps } from "./interface";

export const CUSTOMER_MANAGEMENT_LIST_URL = (page: number, search: string = '', email: string = '', customer_id: number = 0) =>
    `customers?page=${page}&search=${search}&email=${email}${customer_id ? '&customer_id=' + customer_id : ''}`;

export const USER_CUSTOMER_MANAGEMENT_LIST_URL = '/user/customers?page=:page';

export const CUSTOMER_MANAGEMENT_STORE_URL = '/customers';

export const CUSTOMER_MANAGEMENT_UPDATE_URL = (customerId: any) => '/customers/' + customerId;

export const CUSTOMER_SHOW_URL = (customerId: any) => '/customers/' + customerId;

export const ADD_CONTACT_URL = (customerId: any) => '/customers/' + customerId + '/contact';

export const EDIT_CONTACT_URL = (customerId: any, contactId: any) => '/customers/' + customerId + '/contact/' + contactId;

export const CUSTOMER_MANAGEMENT_LIST = async (props: FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
};

export const CUSTOMER_MANAGEMENT_STORE = async (props: FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .post(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const CUSTOMER_MANAGEMENT_UPDATE = async (props: FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .put(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const CUSTOMER_MANAGEMENT_DELETE = async (props: FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .delete(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const CUSTOMER_MANAGEMENT_SHOW = async (props: FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .get(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {
            return { error };
        });
}

export const ADD_CONTACT = async (props: FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .post(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error) => {

            return { error };
        });
}

export const EDIT_CONTACT = async (props: FetchProps) => {
    const { endPoint, body } = props as any;
    return await axios
        .put(endPoint, body)
        .then(({ data }) => {
            return { data };
        })
        .catch((error: any) => {

            return { error };
        });
}