import { convertCurrency } from "utils/offer";
import { MoneyFormat } from "./currency";
import { Chip, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { IconInfoCircle } from "@tabler/icons-react";

export const calculateServicePrice = (service: any, currencies: any) => {
   let servicePrice = parseFloat(service.converted_price);

   // Hizmet kar hesaplaması
   if (service.commission_type == '1') {
      // Sabit kar
      servicePrice += parseFloat(convertCurrency(currencies, service.commission, service.currency, service.converted_currency) as any);
   } else if (service.commission_type == '2') {
      // Yüzde kar
      servicePrice += servicePrice * (parseFloat(service.commission) / 100);
   }

   return parseFloat(servicePrice as any);
};

export const calculateProductPriceUnit = (product: any, currencies: any) => {
   let productPrice = product.price;
   const productJson = product.product_json;

   if (product.converted_currency && product.converted_currency !== product.currency) {
      productPrice = convertCurrency(
         currencies,
         parseFloat(product.price),
         product.currency,
         product.converted_currency
      )

   }



   let commissionPrice = 0;

   if (productJson.commission_type == '2') {
      commissionPrice = parseFloat(productJson.commission);

   }
   else {
      commissionPrice = productPrice * (parseFloat(productJson.commission) / 100);
   }


   let finalPrice = (productPrice + commissionPrice);
   return finalPrice;
};


const calculateProductPriceWithCommission = (product: any, currencies: any) => {
   let productPrice = parseFloat(convertCurrency(currencies, product.price, product.currency, product.converted_currency) as any);
   if (product.product_markup_type == '2') {
      // Sabit kar

      productPrice += parseFloat(product.product_markup);
   } else if (product.product_markup_type == '1') {
      // Yüzde kar

      productPrice += productPrice * (parseFloat(product.product_markup) / 100);
   }





   // 3. Birim başına toplam (Ürün+Kar + Hizmetler+Kar)

   // 4. Adet ile çarpım

   return productPrice;
}
export const calculateProductPrice = (product: any, currencies: any, withQuantity: boolean = true) => {
   let productPrice = calculateProductPriceUnit(product, currencies);
   const productJson = product.product_json;

   if (product.converted_currency && product.converted_currency !== product.currency) {
      productPrice = convertCurrency(
         currencies,
         parseFloat(product.price),
         product.currency,
         product.converted_currency
      )

   }

   productPrice = calculateProductPriceWithCommission(product, currencies);



   productJson.services?.forEach((service: any) => {
      if (service.included_in_total) {

         productPrice += calculateServicePrice(service, currencies);
      }
   });



   return withQuantity ? productPrice * product.product_qty : productPrice;
};

export const calculateProfit = (product: any, currencies: any) => {
   let productPrice = product.price;
   let servicePrice = 0;
   const productJson = product.product_json;

   // // Dahil olan hizmetlerin hesaplanması
   productJson.services?.forEach((service: any) => {
      if (service.included_in_total) {
         if (service.commission_type == '1') {

            servicePrice += parseFloat(service.commission);
         } else {

            servicePrice += servicePrice * (parseFloat(service.commission) / 100);
         }
      }
   });


   if (product.converted_currency && product.converted_currency !== product.currency) {
      productPrice = convertCurrency(
         currencies,
         parseFloat(product.price),
         product.currency,
         product.converted_currency
      )

   }

   let commissionPrice = 0;

   if (productJson.commission_type == '2') {
      commissionPrice = parseFloat(productJson.commission);

   }
   else {
      commissionPrice = productPrice * (parseFloat(productJson.commission) / 100);
   }


   return (commissionPrice + servicePrice) * product.product_qty;
}

export const calculateProfitService = (product: any, currencies: any) => {
   let productPrice = product.price;
   let servicePrice = 0;
   const productJson = product.product_json;

   // // Dahil olan hizmetlerin hesaplanması
   productJson.services?.forEach((service: any) => {
      if (service.included_in_total) {
         if (service.commission_type == "1") {

            servicePrice += parseFloat(service.commission);
         } else {

            servicePrice += service.price * (parseFloat(service.commission) / 100);
         }
      }
   });


   if (product.converted_currency && product.converted_currency !== product.currency) {
      productPrice = convertCurrency(
         currencies,
         parseFloat(product.price),
         product.currency,
         product.converted_currency
      )

   }

   let commissionPrice = 0;




   return (servicePrice) * product.product_qty;
}



export const calculateTotals = (offer: any, currencies: any) => {
   let total = 0;
   let profit = 0;
   let profitService = 0;
   offer?.products?.forEach((product: any) => {
      total += calculateProductPrice(product, currencies);
      profit += calculateProfit(product, currencies);
      profitService += calculateProfitService(product, currencies);
   });

   return { total, profit, profitService };
};

export const FinancialSummary = ({ offer, currencies }: { offer: any, currencies: any }) => {
   const { total, profit, profitService } = calculateTotals(offer, currencies);
   const displayCurrency = 'TRY';

   const convertCurrency = (amount: number, fromCurrency: string, toCurrency: string) => {
      if (fromCurrency === toCurrency) return amount;

      const rateKey = `${fromCurrency}-${toCurrency}`;
      const rate = currencies.find((c: any) => c.currency === rateKey)?.rate || 1;
      return amount * rate;
   };


   const RenderServiceCommission = (service: any) => {
      // {service.commission_type == '1'
      //    ? MoneyFormat(convertCurrency(service.commission, service.currency, productJson.converted_currency) as any, productJson.converted_currency) + '(' + MoneyFormat(service.commission, service.currency) + ')'
      //    : MoneyFormat(service.converted_price * (service.commission / 100), productJson.converted_currency)}

      if (service.commission_type == '1') {
         let extra = '';
         if (service.converted_currency !== service.currency) {
            extra = '(' + MoneyFormat(service.commission, service.currency) + ')';
         }
         return MoneyFormat(convertCurrency(service.commission, service.currency, service.converted_currency) as any, service.converted_currency) + extra;
      } else {
         return MoneyFormat(service.price * (service.commission / 100), service.converted_currency);
      }
   }

   const RenderServicePrice = (service: any) => {
      let extra = '';
      if (service.converted_currency !== service.currency) {
         extra = '(' + MoneyFormat(service.price, service.currency) + ')';
      }
      return MoneyFormat(service.converted_price, service.converted_currency) + extra;
   }

   const RenderExtraUnitPrice = (product: any) => {
      let extra = '';
      if (product.converted_currency !== product.currency) {
         extra = '(' + MoneyFormat(product.price, product.currency) + ')';
      }
      return extra;
   }

   const RenderUnitPrice = (product: any) => {
      let extra = '';
      if (product.converted_currency !== product.currency) {
         extra = '(' + MoneyFormat(product.price, product.currency) + ')';
      }
      return convertCurrency(product.price, product.currency, product.converted_currency);
   }

   const CalculateComission = (item: any) => {

      let productPrice = parseFloat(convertCurrency(item.price, item.currency, item.converted_currency) as any);

      if (item.commission_type == '2') {
         // Sabit kar

         productPrice += parseFloat(item.commission);
      } else if (item.commission_type == '1') {
         // Yüzde kar

         productPrice += productPrice * (parseFloat(item.commission) / 100);
      }



      // 3. Birim başına toplam (Ürün+Kar + Hizmetler+Kar)

      // 4. Adet ile çarpım
      return productPrice;
   }

   const RenderUnitPriceWithCommission = (item: any) => {
      let productPrice = parseFloat(convertCurrency(item.price, item.currency, item.converted_currency) as any);
      if (item.product_markup_type == '2') {
         // Sabit kar

         productPrice += parseFloat(item.product_markup);
      } else if (item.product_markup_type == '1') {
         // Yüzde kar

         productPrice += productPrice * (parseFloat(item.product_markup) / 100);
      }





      // 3. Birim başına toplam (Ürün+Kar + Hizmetler+Kar)

      // 4. Adet ile çarpım
      return productPrice;
   }

   return (
      <div className="space-y-3">
         <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500">Toplam Tutar:</span>
            <Popover placement="left">
               <PopoverTrigger>
                  <span className="font-semibold text-primary cursor-pointer hover:opacity-70 transition-opacity flex items-center gap-1">
                     {MoneyFormat(total, displayCurrency)}
                     <IconInfoCircle size={16} className="text-gray-400" />
                  </span>
               </PopoverTrigger>
               <PopoverContent className="w-96">
                  <div className="p-4 w-full">
                     {offer?.products?.map((product: any, index: number) => {
                        const productJson = product.product_json;
                        return (
                           <div key={index} className="p-3 bg-gray-50 rounded-lg mb-3">
                              <div className="flex items-center justify-between mb-2">
                                 <span className="font-medium">{productJson.name}</span>
                                 <Chip color="primary" variant="flat" size="sm">
                                    {product.product_qty} Adet
                                 </Chip>
                              </div>

                              <div className="space-y-2">
                                 {/* Ürün Fiyat ve Kar */}
                                 <div className="p-2 bg-white rounded">
                                    <div className="flex justify-between mb-1">
                                       <span>Birim Fiyat:</span>
                                       <span>{MoneyFormat(RenderUnitPrice(product), product.converted_currency)} {RenderExtraUnitPrice(product)}</span>
                                    </div>
                                    <div className="flex justify-between">
                                       <span>Kar:</span>
                                       <span className="text-success">
                                          {productJson.commission_type == '2'
                                             ? MoneyFormat(productJson.commission, productJson.converted_currency)
                                             : `%${productJson.commission}`
                                          }
                                       </span>
                                    </div>
                                    <div className="flex justify-between">
                                       <span>Toplam:</span>
                                       <span className="text-success">
                                          {MoneyFormat(RenderUnitPriceWithCommission(product), product.converted_currency)}
                                       </span>
                                    </div>

                                 </div>

                                 {/* Hizmetler */}
                                 {productJson.services?.length > 0 && (
                                    <div className="space-y-2">
                                       {productJson.services.map((service: any, sIndex: number) => (
                                          <div key={sIndex} className="p-2 bg-white rounded">
                                             <div className="flex items-center justify-between mb-1">
                                                <div className="flex items-center gap-2">
                                                   <span>{service.name}</span>
                                                   {service.included_in_total ? (
                                                      <Chip size="sm" variant="flat" color="success">Dahil</Chip>
                                                   ) : (
                                                      <Chip size="sm" variant="flat" color="warning">Hariç</Chip>
                                                   )}
                                                </div>
                                                <span>
                                                   {RenderServicePrice(service)}
                                                </span>
                                             </div>
                                             {service.included_in_total && service.commission > 0 && (
                                                <div className="flex justify-between text-gray-600">
                                                   <span>
                                                      {service.commission_type === '1'
                                                         ? `%${service.commission} Kar:`
                                                         : 'Sabit Kar:'}
                                                   </span>
                                                   <span className="text-success">
                                                      {RenderServiceCommission(service)}
                                                   </span>
                                                </div>
                                             )}

                                             <div className="flex justify-between font-medium border-t border-gray-100 pt-1 mt-1">
                                                <span>Toplam:</span>
                                                <span>{MoneyFormat(calculateServicePrice(service, currencies), service.converted_currency)}</span>
                                             </div>
                                          </div>
                                       ))}
                                    </div>
                                 )}

                                 {/* Ürün Toplamı */}
                                 <div className="flex justify-between p-2 bg-primary/5 rounded font-medium">
                                    <span>Ürün Birim Fiyat Toplam:</span>
                                    <span>{MoneyFormat(calculateProductPrice(product, currencies, false), product.converted_currency)}</span>
                                 </div>
                                 <div className="flex justify-between p-2 bg-primary/5 rounded font-medium">
                                    <span>Toplam:</span>
                                    <span>{MoneyFormat(calculateProductPrice(product, currencies, true), product.converted_currency)}</span>
                                 </div>
                              </div>
                           </div>
                        );
                     })}

                     <div className="p-3 bg-green-50 rounded-lg">
                        <div className="grid grid-cols-3 gap-4">
                           <div>
                              <div className="text-sm text-gray-500">Hizmet Karı</div>
                              <div className="font-medium text-green-600">
                                 {MoneyFormat(profitService, 'TRY')}
                              </div>
                           </div>
                           <div>
                              <div className="text-sm text-gray-500">Toplam Kar</div>
                              <div className="font-medium text-green-600">
                                 {MoneyFormat(profit, 'TRY')}
                              </div>
                           </div>
                           <div>
                              <div className="text-sm text-gray-500">Genel Toplam</div>
                              <div className="font-medium">{MoneyFormat(total, 'TRY')}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               </PopoverContent>
            </Popover>
         </div>

         <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500">Toplam Kar:</span>
            <span className="font-semibold text-success">
               {MoneyFormat(profit, displayCurrency)}
            </span>
         </div>

         <div className="flex items-center justify-between">
            <span className="text-sm text-gray-500">Kar Oranı:</span>
            <span className={`font-semibold ${profit > 0 ? 'text-green-600' : 'text-red-600'}`}>
               %{((profit / (total - profit)) * 100).toFixed(2)}
            </span>
         </div>
      </div>
   );
};

export const FinancialSummaryWithoutPopover = ({ offer, currencies }: { offer: any, currencies: any }) => {
   const { total, profit, profitService } = calculateTotals(offer, currencies);
   const displayCurrency = 'TRY';

   const convertCurrency = (amount: number, fromCurrency: string, toCurrency: string) => {
      if (fromCurrency === toCurrency) return amount;

      const rateKey = `${fromCurrency}-${toCurrency}`;
      const rate = currencies.find((c: any) => c.currency === rateKey)?.rate || 1;
      return amount * rate;
   };


   const RenderServiceCommission = (service: any) => {
      // {service.commission_type == '1'
      //    ? MoneyFormat(convertCurrency(service.commission, service.currency, productJson.converted_currency) as any, productJson.converted_currency) + '(' + MoneyFormat(service.commission, service.currency) + ')'
      //    : MoneyFormat(service.converted_price * (service.commission / 100), productJson.converted_currency)}

      if (service.commission_type == '1') {
         let extra = '';
         if (service.converted_currency !== service.currency) {
            extra = '(' + MoneyFormat(service.commission, service.currency) + ')';
         }
         return MoneyFormat(convertCurrency(service.commission, service.converted_currency, service.currency) as any, service.converted_currency) + extra;
      } else {
         return MoneyFormat(service.price * (service.commission / 100), service.converted_currency);
      }
   }

   const RenderServicePrice = (service: any) => {
      let extra = '';
      if (service.converted_currency !== service.currency) {
         extra = '(' + MoneyFormat(service.price, service.currency) + ')';
      }
      return MoneyFormat(service.converted_price, service.converted_currency) + extra;
   }

   const RenderExtraUnitPrice = (product: any) => {
      let extra = '';
      if (product.converted_currency !== product.currency) {
         extra = '(' + MoneyFormat(product.price, product.currency) + ')';
      }
      return extra;
   }

   const RenderUnitPrice = (product: any) => {
      let extra = '';
      if (product.converted_currency !== product.currency) {
         extra = '(' + MoneyFormat(product.price, product.currency) + ')';
      }
      return convertCurrency(product.price, product.currency, product.converted_currency);
   }

   const CalculateComission = (item: any) => {

      let productPrice = parseFloat(convertCurrency(item.price, item.currency, item.converted_currency) as any);

      if (item.commission_type == '2') {
         // Sabit kar

         productPrice += parseFloat(item.commission);
      } else if (item.commission_type == '1') {
         // Yüzde kar

         productPrice += productPrice * (parseFloat(item.commission) / 100);
      }



      // 3. Birim başına toplam (Ürün+Kar + Hizmetler+Kar)

      // 4. Adet ile çarpım
      return productPrice;
   }

   const RenderUnitPriceWithCommission = (item: any) => {
      let productPrice = parseFloat(convertCurrency(item.price, item.currency, item.converted_currency) as any);
      if (item.product_markup_type == '2') {
         // Sabit kar

         productPrice += parseFloat(item.product_markup);
      } else if (item.product_markup_type == '1') {
         // Yüzde kar

         productPrice += productPrice * (parseFloat(item.product_markup) / 100);
      }





      // 3. Birim başına toplam (Ürün+Kar + Hizmetler+Kar)

      // 4. Adet ile çarpım
      return productPrice;
   }

   return (
      <div className="space-y-3">
         <div className="flex items-center justify-between">
            <div className="p-4 w-full">
               {offer?.products?.map((product: any, index: number) => {
                  const productJson = product.product_json;
                  return (
                     <div key={index} className="p-3 bg-gray-50 rounded-lg mb-3">
                        <div className="flex items-center justify-between mb-2">
                           <span className="font-medium">{productJson.name}</span>
                           <Chip color="primary" variant="flat" size="sm">
                              {product.product_qty} Adet
                           </Chip>
                        </div>

                        <div className="space-y-2">
                           {/* Ürün Fiyat ve Kar */}
                           <div className="p-2 bg-white rounded">
                              <div className="flex justify-between mb-1">
                                 <span>Birim Fiyat:</span>
                                 <span>{MoneyFormat(RenderUnitPrice(product), product.converted_currency)} {RenderExtraUnitPrice(product)}</span>
                              </div>
                              <div className="flex justify-between">
                                 <span>Kar:</span>
                                 <span className="text-success">
                                    {productJson.commission_type == '2'
                                       ? MoneyFormat(productJson.commission, productJson.converted_currency)
                                       : `%${productJson.commission}`
                                    }
                                 </span>
                              </div>
                              <div className="flex justify-between">
                                 <span>Toplam:</span>
                                 <span className="text-success">
                                    {MoneyFormat(RenderUnitPriceWithCommission(product), product.converted_currency)}
                                 </span>
                              </div>

                           </div>

                           {/* Hizmetler */}
                           {productJson.services?.length > 0 && (
                              <div className="space-y-2">
                                 {productJson.services.map((service: any, sIndex: number) => (
                                    <div key={sIndex} className="p-2 bg-white rounded">
                                       <div className="flex items-center justify-between mb-1">
                                          <div className="flex items-center gap-2">
                                             <span>{service.name}</span>
                                             {service.included_in_total ? (
                                                <Chip size="sm" variant="flat" color="success">Dahil</Chip>
                                             ) : (
                                                <Chip size="sm" variant="flat" color="warning">Hariç</Chip>
                                             )}
                                          </div>
                                          <span>
                                             {RenderServicePrice(service)}
                                          </span>
                                       </div>
                                       {service.included_in_total && service.commission > 0 && (
                                          <div className="flex justify-between text-gray-600">
                                             <span>
                                                {service.commission_type === '1'
                                                   ? `%${service.commission} Kar:`
                                                   : 'Sabit Kar:'}
                                             </span>
                                             <span className="text-success">
                                                {RenderServiceCommission(service)}
                                             </span>
                                          </div>
                                       )}

                                       <div className="flex justify-between font-medium border-t border-gray-100 pt-1 mt-1">
                                          <span>Toplam:</span>
                                          <span>{MoneyFormat(calculateServicePrice(service, currencies), service.converted_currency)}</span>
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           )}

                           {/* Ürün Toplamı */}
                           <div className="flex justify-between p-2 bg-primary/5 rounded font-medium">
                              <span>Ürün Birim Fiyat Toplam:</span>
                              <span>{MoneyFormat(calculateProductPrice(product, currencies, false), product.converted_currency)}</span>
                           </div>
                           <div className="flex justify-between p-2 bg-primary/5 rounded font-medium">
                              <span>Toplam:</span>
                              <span>{MoneyFormat(calculateProductPrice(product, currencies, true), product.converted_currency)}</span>
                           </div>
                        </div>
                     </div>
                  );
               })}

               <div className="p-3 bg-green-50 rounded-lg">
                  <div className="grid grid-cols-3 gap-4">
                     <div>
                        <div className="text-sm text-gray-500">Hizmet Karı</div>
                        <div className="font-medium text-green-600">
                           {MoneyFormat(profitService, 'TRY')}
                        </div>
                     </div>
                     <div>
                        <div className="text-sm text-gray-500">Toplam Kar</div>
                        <div className="font-medium text-green-600">
                           {MoneyFormat(profit, 'TRY')}
                        </div>
                     </div>
                     <div>
                        <div className="text-sm text-gray-500">Genel Toplam</div>
                        <div className="font-medium">{MoneyFormat(total, 'TRY')}</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
