//@ts-ignore
import React, { useEffect } from 'react'
import { ThemeProvider } from 'next-themes'
import { Toaster } from 'react-hot-toast'
import { NextUIProvider } from '@nextui-org/react'
import axios from 'axios'
import { apiUrl } from 'api'
import moment from 'moment'
import { Provider } from 'react-redux'
import { store } from './store'
import { routes } from 'config/routes'
import { useTranslation } from 'react-i18next'
import 'moment/locale/tr' // without this line it didn't work
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import Wrapper from 'Wrapper'
window.jQuery = require("jquery");

const queryClient = new QueryClient()

function App() {
   const { t, i18n } = useTranslation()
   axios.interceptors.request.use(
      config => {
         // kimlik bilgilerini gönderme durumu
         config.withCredentials = false
         config.baseURL = apiUrl + '/api'
         if (localStorage.getItem('token')) {
            config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
         }

         return config
      },
      function (error) {
         return Promise.reject(error)
      }
   )

   axios.interceptors.response.use(
      (response: any) => {
         switch (response.data.status) {
            case 401:
               localStorage.removeItem('token')
               localStorage.removeItem('user')
               window.location.href = routes.auth.login
               return

            default:
         }

         return response
      },
      function (error) {
         if (error.response.status === 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            if (window.location.pathname !== routes.auth.login) {
               window.location.href = routes.auth.login
            }
         }
         return Promise.reject(error)
      }
   )

   moment.locale('tr')

   const [loading, setLoading] = React.useState<boolean>(true)

   return (
      <QueryClientProvider client={queryClient}>
         <Provider store={store}>
            <ThemeProvider defaultTheme="light">
               <NextUIProvider>
                  <Wrapper />
               </NextUIProvider>
               <Toaster />
            </ThemeProvider>
         </Provider>
      </QueryClientProvider>
   )
}

export default App
