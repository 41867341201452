import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RequestModalState {
    isOpen: boolean;
    contactId: number | null;
    productsFromCart: boolean;
    isAdded: boolean;
    userId: any;
}

const initialState: RequestModalState = {
    isOpen: false,
    contactId: null,
    productsFromCart: false,
    isAdded: false,
    userId: null
};

export const requestModalSlice = createSlice({
    name: 'requestModal',
    initialState,
    reducers: {
        openRequestModal: (state, action: PayloadAction<{ contactId: number | null, productsFromCart: boolean, userId: number | null }>) => {
            state.isOpen = true;
            state.contactId = action.payload.contactId;
            state.productsFromCart = action.payload.productsFromCart;
            state.userId = action.payload.userId;
        },
        setIsAdded: (state, action: PayloadAction<boolean>) => {
            state.isAdded = action.payload;
        },
        closeRequestModal: (state) => {
            state.isOpen = false;
            state.contactId = null;
        },
        setContactId: (state, action: PayloadAction<number>) => {
            state.contactId = action.payload;
        }
    }
});

export const { openRequestModal, closeRequestModal, setContactId, setIsAdded } = requestModalSlice.actions;
export default requestModalSlice.reducer; 