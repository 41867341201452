import { Button } from '@nextui-org/react'
import { Card, Drawer, Form, Modal, Select, message, List } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Step2 from './Steps/Step2'
import useFetch from 'hooks/use-fetch'
import { IconChevronDown } from '@tabler/icons-react'
import { Alert, Input, Text } from 'rizzui'
import { CUSTOMER_MANAGEMENT_LIST } from 'api/customer-management'
import { useDispatch, useSelector } from 'react-redux'
import { setContactDetail, setContactId, setCurrency, setOfferNumber, setTermTime } from 'features/user/offer'
import { RootState } from 'store'
import Step3 from './Steps/Step3'
import _ from 'lodash'
import { motion, AnimatePresence } from 'framer-motion'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { routes } from 'config/routes'

import {
   OFFER_MANAGEMENT_STORE,
   OFFER_MANAGEMENT_STORE_URL,
   OFFER_MANAGEMENT_UPDATE,
   OFFER_MANAGEMENT_UPDATE_URL
} from 'api/offer-management'
import axios from 'axios'

function Edit() {
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const { id } = useParams<any>()
   const offer = useSelector((state: RootState) => state.offer)
   const [step1DrawerOpen, setStep1DrawerOpen] = useState(true)
   const [step2DrawerOpen, setStep2DrawerOpen] = useState(false)
   const [step3DrawerOpen, setStep3DrawerOpen] = useState(false)
   const [contactsQuery, { data: contactsData, loading: contactsLoading, error: contactsError }] = useFetch<any>()
   const [offerEditMutation, { data: offerEditData, loading: offerEditLoading, error: offerEditError }] =
      useFetch<any>()
   const [currencyModal, setCurrencyModal] = useState(false)
   const [selectedCurrency, setSelectedCurrency] = useState(offer.currency)
   const [currencyData, setCurrencyData] = useState<any>([])
   const [groupedCurrency, setGroupedCurrency] = useState<any>([])
   const [showSuccessModal, setShowSuccessModal] = useState(false)
   const [successMessage, setSuccessMessage] = useState('')

   const windowHeight = window.innerHeight

   const step2DrawerHeight = windowHeight - 0

   const step3DrawerHeight = step2DrawerHeight - 0

   const [form] = Form.useForm()

   const handleStep1 = (values: any) => {
      dispatch(setOfferNumber(values.offer_no))
      dispatch(setContactId(values.contact_id))
      dispatch(setTermTime(values.term_time))
      setStep1DrawerOpen(false)
      setStep2DrawerOpen(true)
   }

   const handleStep2 = (products: any) => {
      setStep2DrawerOpen(false)
      setStep3DrawerOpen(true)
   }

   const saveOffer = (convertToOrder: boolean = false) => {
      //@ts-ignore
      offerEditMutation(OFFER_MANAGEMENT_UPDATE, {
         endPoint: OFFER_MANAGEMENT_UPDATE_URL(id),
         body: {
            ...offer,
            products: offer.products,
            convert_to_order: convertToOrder
         }
      })
   }

   useEffect(() => {
      if (offerEditData) {
         setSuccessMessage(
            offer.sendMail
               ? `Teklifiniz başarıyla oluşturuldu ve ${offerEditData?.offer_email} adresine gönderildi!`
               : 'Teklifiniz başarıyla oluşturuldu!'
         );
         setShowSuccessModal(true);
      }
   }, [offerEditData]);

   useEffect(() => {
      //@ts-ignore
      contactsQuery(CUSTOMER_MANAGEMENT_LIST, {
         endPoint: '/customer-with-contacts'
      })
   }, [])

   useEffect(() => {
      if (step3DrawerOpen) {
         const fetch = async () => {
            const { data } = await axios.get('/get-currency')

            setCurrencyData(data)
            setCurrencyModal(true)

            //group currency from offer.products
            let groupedCurrency = offer.products.map((item: any) => {
               return {
                  currency: item.currency
               }
            })

            //remove duplicate currency
            let uniqueCurrency = _.uniqBy(groupedCurrency, 'currency')

            setGroupedCurrency(uniqueCurrency)
         }
         fetch()
      }
      else {

      }
   }, [step3DrawerOpen])

   return (
      <>
         <Drawer
            placement="bottom"
            height={windowHeight}
            onClose={() => {
               navigate(-1)
            }}
            open={step1DrawerOpen}
            title="Teklifi Düzenle - Adım 1"
            extra={
               <Button type="button" onClick={() => form.submit()} color="primary">
                  İleri
               </Button>
            }
         >
            <div className="max-w-3xl container mx-auto">
               <Form
                  onFinish={handleStep1}
                  initialValues={{
                     offer_no: offer.offerNumber,
                     contact_id: offer.contactId,
                     term_time: offer?.term_time ?? 0
                  }}
                  layout="vertical"
                  form={form}
               >
                  <Card title="Genel Bilgiler" bordered={false}>
                     <div className="">
                        <Form.Item
                           label="Müşteri seçiniz"
                           name="contact_id"
                           rules={[{ required: true, message: 'Lütfen bir müşteri seçiniz!' }]}
                        >
                           <Select
                              onChange={(value: any) => {
                                 const find = contactsData?.data?.find((item: any) =>
                                    item.contacts.some((contact: any) => contact.id === value)
                                 )
                                 if (find) {
                                    const contactDetail = find.contacts.find((contact: any) => contact.id === value)
                                    dispatch(setContactDetail(contactDetail))
                                 }
                              }}
                              options={contactsData?.data
                                 .filter((item: any) => item.contacts.length > 0)
                                 .map((item: any) => {
                                    return {
                                       label: item.company_name,
                                       options: item.contacts.map((contact: any) => {
                                          return {
                                             label: contact.name,
                                             value: contact.id
                                          }
                                       })
                                    }
                                 })}
                              filterOption={(input: any, option: any) =>
                                 option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                 option?.options?.some(
                                    (item: any) => item.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                 )
                              }
                              showSearch
                              placeholder="müşteri seçiniz"
                              suffixIcon={<IconChevronDown />}
                           ></Select>
                        </Form.Item>
                        <Form.Item
                           label="Teklif No"
                           name="offer_no"
                           rules={[{ required: true, message: 'Lütfen bir teklif no giriniz!' }]}
                        >
                           <Input placeholder="Teklif no giriniz" />
                        </Form.Item>
                        <Alert color="danger">Lütfen vade sürenizi gün olarak giriniz örn: 60</Alert>
                        <Form.Item
                           label="Ödeme Vadesi"
                           name="term_time"
                           rules={[{ required: true, message: 'Vade Süresi!' }]}
                        >
                           <Input type="number" value={offer.term_time} placeholder="Vade Süresi" />
                        </Form.Item>
                     </div>
                  </Card>
               </Form>
            </div>
         </Drawer>
         <Step2
            currentProducts={offer.products}
            drawerProps={{
               onClose: () => {
                  setStep2DrawerOpen(false)
               },
               open: step2DrawerOpen
            }}
            handleNext={handleStep2}
            handlePrev={() => {
               setStep2DrawerOpen(false)
               setStep1DrawerOpen(true)
            }}
         />
         <Drawer
            placement="bottom"
            height={step3DrawerHeight}
            onClose={() => {
               setStep3DrawerOpen(false)
            }}
            open={step3DrawerOpen}
            title="Teklifi Önizle - Adım 3"
            extra={
               <div className="flex gap-2">
                  <Button
                     color="primary"
                     onClick={() => {
                        setStep2DrawerOpen(true)
                        setStep3DrawerOpen(false)
                     }}
                  >
                     Geri
                  </Button>
                  <Button
                     isLoading={offerEditLoading}
                     color="primary"
                     onClick={() => {
                        saveOffer(false)
                     }}
                  >
                     {offer.sendMail ? 'Kaydet ve Gönder' : 'Kaydet'}
                  </Button>
                  <Button
                     isLoading={offerEditLoading}
                     color="secondary"
                     onClick={() => {
                        //find baremli fiyat var mı?

                        let checkBaremliFiyat = offer.products.some((item: any) => item.other_prices.length > 0)

                        if (checkBaremliFiyat) {
                           Modal.confirm({
                              title: 'Baremli Fiyat Var',
                              content: 'Bu teklifte baremli fiyatlar var. lütfen kontrol ediniz.',
                              okText: 'Teklifi Görüntüle',
                              cancelText: 'İptal',
                              onOk: () => {
                                 setStep2DrawerOpen(true)
                                 setStep3DrawerOpen(false)
                              }
                           })
                        }
                        else {
                           saveOffer(true)
                        }
                     }}
                  >
                     Kaydet & Siparişe Dönüştür
                  </Button>
               </div>
            }
         >
            <Step3 />
         </Drawer>

         <Transition appear show={showSuccessModal} as={Fragment}>
            <Dialog as="div" className="relative z-[99999]" onClose={() => { }}>
               <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
               >
                  <div className="fixed inset-0 bg-black/90" />
               </Transition.Child>

               <div className="fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                     <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                     >
                        <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden text-center align-middle transition-all">
                           <motion.div
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ delay: 0.2 }}
                              className="relative"
                           >
                              {/* Animasyonlu Arka Plan Daireler */}
                              <motion.div
                                 animate={{
                                    rotate: 360,
                                    scale: [1, 1.2, 1],
                                 }}
                                 transition={{
                                    duration: 8,
                                    repeat: Infinity,
                                    ease: "linear"
                                 }}
                                 className="absolute inset-0 rounded-full border-4 border-primary/30"
                              />
                              <motion.div
                                 animate={{
                                    rotate: -360,
                                    scale: [1.2, 1, 1.2],
                                 }}
                                 transition={{
                                    duration: 8,
                                    repeat: Infinity,
                                    ease: "linear"
                                 }}
                                 className="absolute inset-2 rounded-full border-4 border-secondary/40"
                              />
                              <motion.div
                                 animate={{
                                    rotate: 360,
                                    scale: [1, 1.1, 1],
                                 }}
                                 transition={{
                                    duration: 8,
                                    repeat: Infinity,
                                    ease: "linear"
                                 }}
                                 className="absolute inset-4 rounded-full border-4 border-primary/50"
                              />

                              {/* İçerik */}
                              <div className="relative z-10 p-12">
                                 <motion.div
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    transition={{
                                       type: "spring",
                                       stiffness: 260,
                                       damping: 20
                                    }}
                                    className="w-24 h-24 rounded-full bg-green-100 flex items-center justify-center mx-auto mb-6"
                                 >
                                    <motion.div
                                       animate={{
                                          scale: [1, 1.2, 1],
                                          opacity: [0.5, 1, 0.5]
                                       }}
                                       transition={{
                                          duration: 2,
                                          repeat: Infinity,
                                          ease: "easeInOut"
                                       }}
                                       className="text-4xl"
                                    >
                                       ✨
                                    </motion.div>
                                 </motion.div>

                                 <Dialog.Title
                                    as={motion.h3}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.3 }}
                                    className="text-3xl font-bold text-white mb-4"
                                 >
                                    Başarılı!
                                 </Dialog.Title>

                                 <motion.p
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                    className="text-lg text-gray-300 mb-8"
                                 >
                                    {successMessage}
                                 </motion.p>

                                 <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.5 }}
                                    className="space-y-4"
                                 >
                                    <Button
                                       size="lg"
                                       color="success"
                                       variant="shadow"
                                       className="w-64"
                                       onClick={() => {
                                          navigate(routes.offerManagement.list);
                                       }}
                                    >
                                       Tekliflerime Git
                                    </Button>
                                 </motion.div>
                              </div>
                           </motion.div>
                        </Dialog.Panel>
                     </Transition.Child>
                  </div>
               </div>
            </Dialog>
         </Transition>
      </>
   )
}

export default Edit
