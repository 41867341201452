import { Button } from '@nextui-org/react';
import {
   IconConePlus,
   IconCubePlus,
   IconSearch,
   IconTrash,
   IconInfoCircle,
   IconChevronRight,
   IconCalculator,
   IconCurrency,
   IconChevronDown,
   IconPlus,
} from '@tabler/icons-react';
import {
   Card,
   Divider,
   Drawer,
   DrawerProps,
   Empty,
   Form,
   Image,
   InputNumber,
   Modal,
   Popover,
   Select,
   Table,
   Upload,
   message,
} from 'antd';
import { setOtherSuppliers, setProduct } from 'features/user/offer';
import useFetch from 'hooks/use-fetch'
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Loader, Input as InputRiz, Alert, Switch } from 'rizzui';
import { RootState } from 'store'
import _ from 'lodash';
import axios from 'axios'
import { MoneyFormat, MoneyFormat3 } from 'hooks/currency';
import { apiUrl } from '../../../../api'
import { getDescription } from 'utils/filter-data'
import Products from 'pages/admin/Products'
import { motion, AnimatePresence } from 'framer-motion'
import moment from 'moment'
import { Badge } from '@nextui-org/react'
import RichTextEditor from 'components/RichTextEditor'
import QuillEditor from 'components/ui/quill-editor'
import { CurrencyRatesDisplay } from 'hooks/currency-component'

interface Props {
   drawerProps: DrawerProps
   handleNext: (products: any) => void
   handlePrev: () => void
   currentProducts?: any[]
}

interface Service {
   name: string;
   price: number;
   included_in_total?: boolean;
}

const CustomDrawer = ({ isOpen, onClose, title, children, extra, ...props }: any) => {
   return (
      <motion.div
         initial={{ y: "100%" }}
         animate={{ y: isOpen ? "0%" : "100%" }}
         transition={{ type: "spring", damping: 30, stiffness: 300 }}
         className="fixed inset-0 z-50"
      >
         {/* Backdrop */}
         <div
            className="absolute inset-0 bg-black/20 backdrop-blur-sm"
            onClick={onClose}
         />

         {/* Drawer Content */}
         <div className="
            absolute bottom-0 left-0 right-0
            h-screen w-full
            bg-white
            rounded-t-2xl
            shadow-2xl
            flex flex-col
         ">
            {/* Header */}
            <div className="
               flex items-center justify-between
               px-6 py-4
               border-b border-gray-100
            ">
               <h2 className="text-lg font-medium text-gray-800">
                  {title}
               </h2>
               {extra}
            </div>

            {/* Body */}
            <div className="flex-1 overflow-y-auto">
               {children}
            </div>
         </div>
      </motion.div>
   );
};

export default function Step2(props: Props) {
   const [currencyDrawer, setCurrencyDrawer] = useState(false)
   const { drawerProps, handleNext, handlePrev } = props
   const [productImage, setProductImage] = useState<any>(null)
   const [addProductModal, setAddProductModal] = useState(false)
   const [addProductForm] = Form.useForm()
   const [products, setProducts] = useState<any[]>(props.currentProducts || [])
   const [open, setOpen] = useState(false)
   const [searchQuery, { data, loading }] = useFetch<any>()
   const [serviceQuery, { data: serviceData, loading: serviceLoading }] = useFetch<any>()
   const [search, setSearch] = useState('')
   const offer = useSelector((state: RootState) => state.offer)
   const dispatch = useDispatch()
   const bodyRef = React.useRef<HTMLDivElement>(null)
   const drawerFormRef = React.useRef<any>(null)
   const [productForm] = Form.useForm()
   const [selectedIndex, setSelectedIndex] = useState<any>(null)
   const [servicesDrawer, setServicesDrawer] = useState(false)
   const [serviceSearchModal, setServiceSearchModal] = useState(false)
   const [errors, setErrors] = useState<any>([])
   const [searchs, setSearchs] = useState([])

   const serviceButtonRef = React.useRef<any>(null)

   const [supplierModal, setSupplierModal] = useState(false)
   const [supplierForm] = Form.useForm()
   const [suppliers, setSuppliers] = useState<any[]>([])

   const [currencies, setCurrencies] = useState<any[]>([])

   const [reserveCheckModal, setReserveCheckModal] = useState(false);
   const [reserveUserName, setReserveUserName] = useState('');
   const [checkingProduct, setCheckingProduct] = useState<any>(null);
   const [checkReserveMutation] = useFetch();
   const [otherPrices, setOtherPrices] = useState<any[]>([])
   const [otherPriceModal, setOtherPriceModal] = useState(false)

   const getSuppliers = () => {
      const response = fetch(apiUrl + '/api/supplier-list', {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })
         .then(res => res.json())
         .then(data => {

            let suppliers = data.data.map((item: any) => {
               return {
                  supplier: item.supplier
               }
            })


            setSuppliers(suppliers)
         })
   }

   const searchProduct = async () => {
      const response = await fetch(apiUrl + '/api/search-product?search=' + search, {
         headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token')
         }
      })

      const data = await response.json()

      setSearchs(data.data.data)
   }

   useEffect(() => {
      if (search.length >= 3) {
         const url = 'https://test.gimel.com.tr/index.php?route=api/crm/searchProduct&search=' + search
         searchProduct()
      }
   }, [search])

   useEffect(() => {
      getSuppliers()

      const fetchCurrencies = async () => {
         const response = await axios.get('/get-currency');
         setCurrencies(response.data);
      }

      fetchCurrencies();
   }, [])

   const addProductToOffer = (product: any) => {
      const findProduct = products.find(item => item.id === product.product_id)
      if (findProduct) {

         message.error('Bu ürün zaten ekli')
         return
      }
      let price = product.price.split(' ')[0]
      price = price.replace(',', '.')
      price = parseFloat(price).toFixed(2)
      let productArray = {
         id: product.id,
         name: product.name,
         model: product.model,
         price: price,
         stock: product.quantity,
         product_qty: product.quantity,
         delivery_date: '',
         image: product.image,
         commission: 0,
         commission_type: '3',
         description: getDescription(product),
         services: [],
         isReserve: false,
         reserveEndDate: '',
         supplier: product.supplier,
         currency: product.currency
      }



      setProducts([...products, productArray])
   }

   const calcServicePrice = (item: any, service: any) => {
      const productCurrency = item.currency;
      const serviceCurrency = service.currency;
      let servicePrice = parseFloat(service.price);
      const rateKey = `${serviceCurrency}-${productCurrency}`;
      const rate = currencies.find(c => c.currency === rateKey)?.rate || 1;
      servicePrice = servicePrice * rate; // TRY'den ürün kuruna çevir

      return servicePrice;
   }

   const handleAddServiceToProduct = (item: any) => {
      let newProducts = _.cloneDeep(products);
      const findService = newProducts[selectedIndex].services.find((service: any) => service.id === item.id);

      if (findService) {
         message.error('Bu servis zaten ekli');
         return;
      }

      // Hizmet fiyatını TRY olarak al
      let servicePrice = parseFloat(item.price);
      const productCurrency = newProducts[selectedIndex].currency;
      const serviceCurrency = 'TRY'; // Sabit TRY


      if (productCurrency !== serviceCurrency) {
         const rateKey = `${serviceCurrency}-${productCurrency}`;
         const rate = currencies.find(c => c.currency === rateKey)?.rate || 1;
         servicePrice = servicePrice / rate; // TRY'den ürün kuruna çevir
      }

      newProducts[selectedIndex].services.push({
         ...item,
         original_price: servicePrice, // TRY fiyatı sakla
         original_currency: 'TRY',    // TRY olarak sakla
         price: servicePrice,         // Şimdilik aynı fiyat, çevrim sadece included_in_total true olduğunda yapılacak
         commission: 0,
         commission_type: '0',
         description: '',
         included_in_total: true,
      });

      // Form alanlarını güncelle
      const product_id = newProducts[selectedIndex].id;
      const index = newProducts[selectedIndex].services.length - 1;

      serviceForm.setFieldsValue({
         [`name_${product_id}_${index}`]: item.name,
         [`price_${product_id}_${index}`]: servicePrice, // TRY fiyat
         [`commission_${product_id}_${index}`]: 0,
         [`commission_type_${product_id}_${index}`]: '0',
         [`service_description_${product_id}_${index}`]: '',
         [`included_in_total_${product_id}_${index}`]: true
      });

      setProducts(newProducts);
      setServiceSearchModal(false);
      dispatch(setProduct(products));

   }

   const handleReUpdateServicePrice = (productIndex: any, field: any, value: any) => {
      const newProducts = _.cloneDeep(products);
      newProducts[productIndex].services.forEach((service: any) => {
         service.converted_price = convertCurrency(service.price, service.currency, value) as any;
      });
      newProducts[productIndex][field] = value;
      setProducts(newProducts);

      dispatch(setProduct([...newProducts]));
   }

   const CalculateComissionUnit = (item: any) => {
      let productPrice = parseFloat(convertCurrency(item.price, item.currency, item.converted_currency) as any);

      if (item.commission_type == '2') {
         // Sabit kar
         productPrice += parseFloat(item.commission);
      } else if (item.commission_type == '1') {
         // Yüzde kar
         productPrice += productPrice * (parseFloat(item.commission) / 100);
      }

      // 2. Hizmetlerin hesaplanması (her biri kendi kar oranıyla)
      let servicesTotal = 0;
      if (item.services?.length > 0) {
         item.services.map((service: any) => {
            if (service.included_in_total) {
               servicesTotal += calculateServicePrice(service);
            }
         });
      }

      // 3. Birim başına toplam (Ürün+Kar + Hizmetler+Kar)
      const totalPerUnit = productPrice + servicesTotal;

      return MoneyFormat(totalPerUnit, item.converted_currency);
   };

   const onChangeProductInput = useCallback((productIndex: any, field: any, value: any, force?: boolean) => {
      const newProducts = _.cloneDeep(products);

      if (field === 'commission_type') {
         // Kar tipi değiştiğinde komisyon değerini sıfırla
         newProducts[productIndex][field] = value;
         if (force) {
            const unitPrice = convertCurrency(newProducts[productIndex].price, newProducts[productIndex].currency, newProducts[productIndex].converted_currency) as any;
            const salesPrice = newProducts[productIndex].sales_price;
            newProducts[productIndex].commission = (salesPrice - unitPrice).toFixed(2);
         }
         else {
            newProducts[productIndex].commission = 0;
         }
      }
      else if (field == 'calculate_comission') {
         newProducts[productIndex].comission_type = "2";
         newProducts[productIndex].commission = value;
      }
      else if (field === 'description') {
         // For RichTextEditor, only update if content actually changed
         if (newProducts[productIndex][field] !== value) {
            newProducts[productIndex][field] = value;
            newProducts[productIndex].unit_price = CalculateComissionUnit(newProducts[productIndex]);
            setProducts(newProducts);
            dispatch(setProduct([...newProducts]));
         }
         return; // Early return for description field
      }
      else {
         newProducts[productIndex][field] = value;
      }

      newProducts[productIndex].unit_price = CalculateComissionUnit(newProducts[productIndex]);
      setProducts(newProducts);
      dispatch(setProduct([...newProducts]));

      if (field == 'converted_currency') {
         handleReUpdateServicePrice(productIndex, field, value);
      }
   }, [products, dispatch, handleReUpdateServicePrice, CalculateComissionUnit]);

   const handleCheckProductValidation = () => {
      const newErrors: any[] = [];

      products.forEach((item, index) => {
         const productErrors: any = {};
         let validated = true;

         if (!item.price || item.price === 0) {
            productErrors.price = 'Lütfen bir fiyat giriniz';
            validated = false;
         }

         if (!item.stock || item.stock === 0) {
            productErrors.stock = 'Lütfen bir stok miktarı giriniz';
            validated = false;
         }

         if (!item.delivery_date) {
            productErrors.delivery_date = 'Lütfen bir teslim iş gününü giriniz';
            validated = false;
         }



         if (item.commission_type === '3' || item.commission_type === null) {
            productErrors.commission_type = 'Lütfen bir komisyon tipi seçiniz';
            validated = false;
         }

         if (!validated) {
            newErrors[index] = productErrors;
         }
      });

      setErrors(newErrors);
      return newErrors.length === 0;
   };

   const RenderValidatioError = (productIndex: any, field: any) => {
      if (errors[productIndex]?.[field]) {
         return <span className="text-red-500 text-sm">{errors[productIndex]?.[field]}</span>
      }
      return null
   }

   const [serviceForm] = Form.useForm()

   const handleScroll = (e: any) => {
      //fixed service ref button on scroll

      if (drawerFormRef.current) {
         if (e.target.scrollTop > drawerFormRef.current.offsetTop) {
            serviceButtonRef.current?.classList.add('fixed', 'bottom-0', 'left-0', 'right-0', 'z-50')
         } else {
            serviceButtonRef.current?.classList.remove('fixed', 'bottom-0', 'left-0', 'right-0', 'z-50')
         }
      } else {
      }
   }

   const handleServiceSave = (e: any) => {

      const productServices = products[selectedIndex].services.map((item: any, index: number) => {
         const product_id = products[selectedIndex].id;
         const servicePriceTRY = parseFloat(e[`price_${product_id}_${index}`]);
         const productCurrency = products[selectedIndex].converted_currency;
         let convertedPrice = servicePriceTRY;

         // Eğer birim maliyete dahil edilecekse ve para birimleri farklıysa çevrim yap

         const rateKey = `${item.converted_currency}-${productCurrency}`;
         const rate = currencies.find(c => c.currency === rateKey)?.rate || 1;

         convertedPrice = servicePriceTRY * rate; // TRY'den ürün kuruna çevir

         return {
            ...item,
            name: e[`name_${product_id}_${index}`],
            converted_price: convertedPrice, // Çevrilmiş fiyat,
            price: servicePriceTRY,
            original_price: servicePriceTRY, // TRY olarak orijinal fiyat
            original_currency: 'TRY',
            converted_currency: productCurrency,
            commission: e[`commission_${product_id}_${index}`],
            commission_type: e[`commission_type_${product_id}_${index}`],
            service_description: e[`description_${product_id}_${index}`],
            included_in_total: e[`included_in_total_${product_id}_${index}`] || false,
            currency: e[`currency_${product_id}_${index}`] ?? 'TRY',
         };
      });



      let newProducts = _.cloneDeep(products);
      newProducts[selectedIndex].services = productServices;
      setProducts((prev: any) => newProducts);
      setServicesDrawer(false);
      message.success('Hizmetler kaydedildi');

   }

   const findIndexProductId = (id: any) => {
      const selectedIndex = products.findIndex(item => item.id === id)
      setSelectedIndex(selectedIndex)
      if (products[selectedIndex].services.length > 0) {
         const product_id = products[selectedIndex].id

         products[selectedIndex].services.map((item: any, index: number) => {

            serviceForm.setFieldsValue({
               [`name_${product_id}_${index}`]: item.name,
               [`price_${product_id}_${index}`]: item.price,
               [`commission_${product_id}_${index}`]: item.commission,
               [`commission_type_${product_id}_${index}`]: item.commission_type,
               [`service_description_${product_id}_${index}`]: item.service_description,
               [`included_in_total_${product_id}_${index}`]: item.included_in_total,
               [`converted_currency_${product_id}_${index}`]: item.converted_currency,
               [`original_currency_${product_id}_${index}`]: item.original_currency,
               [`converted_price_${product_id}_${index}`]: item.converted_price,
               [`currency_${product_id}_${index}`]: item.currency
            })

            handleReForceServicePrice(index, item.currency, selectedIndex)
         })


      }
   }

   const calculateServicePrice = (service: any) => {
      let servicePrice = parseFloat(service.converted_price);

      // Hizmet kar hesaplaması
      if (service.commission_type == '1') {
         // Sabit kar
         servicePrice += parseFloat(convertCurrency(service.commission, service.currency, service.converted_currency) as any);
      } else if (service.commission_type == '2') {
         // Yüzde kar
         servicePrice += servicePrice * (parseFloat(service.commission) / 100);
      }

      return parseFloat(servicePrice as any);
   };

   const CalculateComission = (item: any) => {

      let productPrice = parseFloat(convertCurrency(item.price, item.currency, item.converted_currency) as any);

      if (item.commission_type == '2') {
         // Sabit kar

         productPrice += parseFloat(item.commission);
      } else if (item.commission_type == '1') {
         // Yüzde kar

         productPrice += productPrice * (parseFloat(item.commission) / 100);
      }


      // 2. Hizmetlerin hesaplanması (her biri kendi kar oranıyla)
      let servicesTotal = 0;
      if (item.services?.length > 0) {
         item.services.map((service: any) => {
            if (service.included_in_total) {
               servicesTotal += calculateServicePrice(service);
            }
         })
      }

      // 3. Birim başına toplam (Ürün+Kar + Hizmetler+Kar)
      const totalPerUnit = productPrice + servicesTotal;

      // 4. Adet ile çarpım
      const finalTotal = totalPerUnit * item.stock;

      return MoneyFormat(finalTotal, item.converted_currency);
   }

   const groupByXmlFirm = () => {
      //only supplier return
      setSuppliers([])
      dispatch(setOtherSuppliers({}))
      const xmlFirms = products.map(item => item.supplier)
      const uniqueXmlFirms = [...(new Set(xmlFirms) as any)]


      const groupedProducts = uniqueXmlFirms.map(xmlFirm => {
         return {
            supplier: xmlFirm,
            products: products.filter(item => item.supplier === xmlFirm)
         }
      })

      //groupedProducts listele tedarikçi 250k dan fazla olanları listele toplam tutarları



      const totalPrices = groupedProducts.map(item => {
         return item.products.reduce((acc, product) => {
            const price = acc + parseFloat(product.price) * product.stock

            if (price > 250000) {
               return {
                  total: price,
                  supplier: item.supplier
               }
            }
         }, 0)
      })
      let offerSuppliers = offer.otherSuppliers


      if (totalPrices.length == 0) {
         dispatch(setProduct(products))
         handleNext(products)
      } else {
         // if (totalPrices.length > 0) {
         //    if (totalPrices[0] !== undefined) {
         //       setSuppliers(totalPrices)
         //       setSupplierModal(true)
         //    } else {
         //       dispatch(setProduct(products))
         //       handleNext(products)
         //    }
         // } else {

         // }

         let productArray = _.cloneDeep(products)
         productArray.forEach((item: any) => {
            item.unit_price = CalculateComissionUnit(item);
         })

         dispatch(setProduct(productArray))
         handleNext(productArray)
      }
   }




   // HTML'den plain text çıkaran yardımcı fonksiyon
   const getPlainTextFromHtml = (html: string) => {
      const temp = document.createElement('div');
      temp.innerHTML = html;
      return temp.textContent || temp.innerText || '';
   };

   // Rezervasyon kontrolü
   const checkReserve = async (productModel: string, newStock: number, index: number, product: any) => {
      try {
         const datas = await fetch(`${apiUrl}/api/offers/check-reserve`, {
            method: 'POST',
            body: JSON.stringify({ product_model: productModel }),
            headers: {
               'Content-Type': 'application/json',
               'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
         })

         const response = await datas.json()

         if (response.status) {
            setReserveUserName(response.user);
            setCheckingProduct({
               ...product,
               newStock,
               index,
               reserveDate: response.reserve_date,
               reservedQty: response.product_qty
            });
            setReserveCheckModal(true);
         } else {
            onChangeProductInput(index, 'stock', newStock);
         }
      } catch (error) {


      }
   };

   // Stok input değişikliği
   const handleStockChange = (e: any, index: number, product: any) => {
      const newStock = e.target.value;

      onChangeProductInput(index, 'stock', newStock);
   };

   const modules = {
      toolbar: [
         [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
         [{ 'font': [] }],
         [{
            'size': [
               '12px',
               '14px',    // Minimum font size
               '16px',
               '18px',
               '20px',
               '24px',
               '28px',
               '32px',
               '36px',
               '40px',
               '48px'
            ]
         }],
         ['bold', 'italic', 'underline', 'strike'],
         [{ 'color': [] }, { 'background': [] }],
         [{ 'list': 'ordered' }, { 'list': 'bullet' }],
         [{ 'align': [] }],
         ['link', 'image'],
         ['clean']
      ]
   };

   // Custom CSS ekleyin
   const formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'color',
      'background',
      'list',
      'bullet',
      'align',
      'link',
      'image'
   ];

   // Para birimi çevirme fonksiyonu
   const convertCurrency = (amount: number, fromCurrency: string, toCurrency: string) => {
      if (fromCurrency === toCurrency) return amount;

      const rateKey = `${fromCurrency}-${toCurrency}`;
      const rate = currencies.find(c => c.currency === rateKey)?.rate || 1;
      return amount * rate;
   };

   // Fiyat gösterimi için yeni komponent
   const PriceDisplay = ({ item }: { item: any }) => {
      const originalPrice = MoneyFormat(item.price, item.currency);

      if (item.converted_currency && item.converted_currency !== item.currency) {
         const convertedAmount = convertCurrency(
            parseFloat(item.price),
            item.currency,
            item.converted_currency
         );
         return (
            <div>
               <span>{MoneyFormat(convertedAmount, item.converted_currency)}</span>
               <span className="text-gray-500 text-sm ml-2">
                  ({originalPrice})
               </span>
            </div>
         );
      }

      return <span>{originalPrice}</span>;
   };




   const handleReForceServicePrice = (index: number, value: string, selectedIndex: number) => {

      let newProducts = _.cloneDeep(products);
      const service = newProducts[selectedIndex].services[index];
      const productCurrency = newProducts[selectedIndex].converted_currency;

      // Yeni kur ile fiyatı güncelle
      const rateKey = `${value}-${productCurrency}`;
      const rate = currencies.find(c => c.currency === rateKey)?.rate || 1;
      const newPrice = parseFloat(service.original_price) * rate;

      service.currency = value ?? 'TRY';
      service.price = newPrice;
      service.converted_price = convertCurrency(service.price, service.converted_currency, value) as any;
      service.converted_currency = value;
      setProducts(newProducts)




   }

   // Para birimi seçimi için komponent
   const CurrencySelector = ({ item, index, onChangeProductInput }: any) => {
      return (
         <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
               Çevirmek istediğiniz para birimi
            </label>
            <select
               className="w-full p-2 select-item border border-gray-300 rounded-md"
               value={item.converted_currency || item.currency}
               onChange={e => {
                  onChangeProductInput(index, 'converted_currency', e.target.value);

                  //hizmetlerdeki fiyatları güncelle


               }}
            >
               <option value="TRY">TRY</option>
               <option value="USD">USD</option>
               <option value="EUR">EUR</option>
               <option value="GBP">GBP</option>
            </select>
         </div>
      );
   };

   // Hesaplama açıklama komponenti
   const PriceCalculationCard = ({ item }: { item: any }) => {
      const [isOpen, setIsOpen] = useState(false);

      return (
         <Card className="bg-gray-50/50 border-none">

            {/* Collapse Header */}
            <button
               onClick={() => setIsOpen(!isOpen)}
               className="w-full mt-4 flex items-center justify-between p-4 hover:bg-gray-100 rounded-lg transition-colors"
            >
               <span className="text-sm font-medium text-gray-700">Hesaplama Detayı</span>
               <motion.div
                  animate={{ rotate: isOpen ? 180 : 0 }}
                  transition={{ duration: 0.2 }}
               >
                  <IconChevronDown className="w-5 h-5 text-gray-500" />
               </motion.div>
            </button>

            {/* Collapse Content */}
            <AnimatePresence>
               {isOpen && (
                  <motion.div
                     initial={{ height: 0, opacity: 0 }}
                     animate={{ height: "auto", opacity: 1 }}
                     exit={{ height: 0, opacity: 0 }}
                     transition={{ duration: 0.2 }}
                     className="overflow-hidden"
                  >
                     <div className="grid grid-cols-3 gap-4">
                        <div className="p-3 bg-white rounded-lg shadow-sm">
                           <div className="text-sm text-gray-500">Birim Fiyat</div>
                           <div className='flex gap-2 items-center'>
                              <div className="text-lg font-semibold text-primary">
                                 <PriceDisplay item={item} />
                              </div>
                           </div>
                        </div>

                        <div className="p-3 bg-white rounded-lg shadow-sm">
                           <div className="text-sm text-gray-500">Ürün Stoğu</div>
                           <div className="text-lg font-semibold">{item.product_qty} Adet</div>
                        </div>

                        <div className="p-3 bg-white rounded-lg shadow-sm">
                           <div className="text-sm text-gray-500">Miktar</div>
                           <div className="text-lg font-semibold">{item.stock} Adet</div>
                        </div>

                        <div className="p-3 bg-white rounded-lg shadow-sm">
                           <div className="text-sm text-gray-500">Kâr</div>
                           <div className="text-lg font-semibold text-success">
                              {item.commission} {item.commission_type == '2' ? item.converted_currency : '%'}
                           </div>
                        </div>
                     </div>

                     <div className="space-y-4 p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm font-medium text-gray-700 mb-2">Hesaplama Detayı</div>
                        <div className="space-y-2">
                           <div className="flex items-center gap-2">
                              <Badge color="primary" variant="flat">1</Badge>
                              <span className="font-medium">Ürün Birim Fiyat:</span>
                              <PriceDisplay item={item} />
                           </div>
                        </div>

                        <div className="space-y-2">
                           <div className="flex items-center gap-2">
                              <Badge color="primary" variant="flat">2</Badge>
                              <span className="font-medium">Ürün Kar Hesaplaması:</span>
                              {item.commission_type == '2' ? (
                                 <span>+ Sabit {MoneyFormat(parseFloat(item.commission), item.converted_currency)} {item.converted_currency !== item.currency ?
                                    '(' + MoneyFormat(convertCurrency(item.commission, item.converted_currency, item.currency), item.currency) + ')' : null}</span>
                              ) : (
                                 <span>+ %{item.commission} ({MoneyFormat(parseFloat(convertCurrency(item.price, item.currency, item.converted_currency) as any) * (parseFloat(item.commission) / 100), item.converted_currency)})</span>
                              )}
                           </div>
                           <div className="ml-6 text-gray-600">
                              Karlı Birim Fiyat: {MoneyFormat(
                                 item.commission_type == '2'
                                    ? parseFloat(convertCurrency(item.price, item.currency, item.converted_currency) as any) + parseFloat(item.commission)
                                    : parseFloat(convertCurrency(item.price, item.currency, item.converted_currency) as any) * (1 + parseFloat(item.commission) / 100),
                                 item.converted_currency
                              )}
                           </div>
                        </div>
                        <div className="flex items-center gap-2">
                           <Badge color="primary" variant="flat">3</Badge>
                           <span className="font-medium">Adet Hesaplaması:</span>
                           <span>× {item.stock} adet</span>
                        </div>
                        <div className="flex items-center gap-2">
                           <Badge color="primary" variant="flat">4</Badge>
                           <span className="font-medium">Ürün Ara Toplam:</span>
                           {item.commission_type == '2' ? (
                              <span>{MoneyFormat((parseFloat(
                                 convertCurrency(item.price, item.currency, item.converted_currency) as any

                              ) + parseFloat(item.commission)) * item.stock, item.converted_currency)}</span>
                           ) : (
                              <span>{MoneyFormat(convertCurrency(item.price, item.currency, item.converted_currency) as any * item.stock * (1 + parseFloat(item.commission) / 100), item.converted_currency)}</span>
                           )}


                        </div>

                        {item.services?.length > 0 && (
                           <div className="space-y-2">
                              <div className="flex items-center gap-2">
                                 <Badge color="primary" variant="flat">5</Badge>
                                 <span className="font-medium">Hizmet Hesaplamaları:</span>
                              </div>



                              {/* Birime Dahil Hizmetler */}
                              {item.services.some((s: any) => s.included_in_total) && (
                                 <div className="ml-6 mb-4">
                                    <div className="bg-blue-50 p-3 rounded-lg border border-blue-100">
                                       <div className="font-medium text-blue-700 mb-3 flex items-center gap-2">
                                          <Badge color="primary">Birim Maliyete Dahil Hizmetler</Badge>
                                       </div>




                                       <div className="space-y-3">
                                          {item.services
                                             .filter((service: any) => service.included_in_total)
                                             .map((service: any, idx: any) => (
                                                <div key={idx} className="bg-white p-3 rounded-lg space-y-1">
                                                   <div className="font-medium text-gray-700">{service.name}</div>
                                                   <div className="text-gray-600 space-y-1">
                                                      <div className="flex justify-between">
                                                         <span>Hizmet Fiyatı:</span>

                                                         {service.converted_currency !== service.currency ? (
                                                            <span>{MoneyFormat(service.converted_price, item.converted_currency)} ({MoneyFormat(service.price, service.currency)})</span>
                                                         ) : (
                                                            <span>{MoneyFormat(service.converted_price, item.converted_currency)}</span>
                                                         )}
                                                      </div>
                                                      {service.commission > 0 && (
                                                         <div className="flex justify-between">
                                                            <span>
                                                               {service.commission_type == '1' ? 'Sabit Kar:' : `%${service.commission} Kar:`}
                                                            </span>
                                                            <span>
                                                               {service.commission_type == '1'
                                                                  ? MoneyFormat(convertCurrency(service.commission, service.currency, item.converted_currency) as any, item.converted_currency) + '(' + MoneyFormat(service.commission, service.currency) + ')'
                                                                  : MoneyFormat(service.converted_price * (service.commission / 100), item.converted_currency)}
                                                            </span>
                                                         </div>
                                                      )}
                                                      <div className="flex justify-between font-medium border-t border-gray-100 pt-1">
                                                         <span>Toplam:</span>
                                                         <span>{MoneyFormat(calculateServicePrice(service), item.converted_currency)}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             ))
                                          }
                                       </div>
                                    </div>
                                 </div>
                              )}

                              {/* Harici Hizmetler */}
                              {item.services.some((s: any) => !s.included_in_total) && (
                                 <div className="ml-6">
                                    <div className="bg-gray-100 p-3 rounded-lg border border-gray-200">
                                       <div className="font-medium text-gray-700 mb-3 flex items-center gap-2">
                                          <Badge color="default">Harici Hizmetler</Badge>
                                       </div>
                                       <div className="space-y-3">
                                          {item.services
                                             .filter((service: any) => !service.included_in_total)
                                             .map((service: any, idx: any) => (
                                                <div key={idx} className="bg-white p-3 rounded-lg space-y-1">
                                                   <div className="font-medium text-gray-700">{service.name}</div>
                                                   <div className="text-gray-600 space-y-1">
                                                      <div className="flex justify-between">
                                                         <span>Hizmet Fiyatı:</span>
                                                         <span>{MoneyFormat(service.price, item.currency)}</span>
                                                      </div>
                                                      {service.commission > 0 && (
                                                         <div className="flex justify-between">
                                                            <span>
                                                               {service.commission_type === '2' ? 'Sabit Kar:' : `%${service.commission} Kar:`}
                                                            </span>
                                                            <span>
                                                               {service.commission_type === '2'
                                                                  ? MoneyFormat(service.commission, item.currency)
                                                                  : MoneyFormat(service.price * (service.commission / 100), item.currency)}
                                                            </span>
                                                         </div>
                                                      )}
                                                      <div className="flex justify-between font-medium border-t border-gray-100 pt-1">
                                                         <span>Toplam:</span>
                                                         <span>{MoneyFormat(calculateServicePrice(service), item.currency)}</span>
                                                      </div>
                                                   </div>
                                                </div>
                                             ))
                                          }
                                       </div>
                                    </div>
                                 </div>
                              )}
                           </div>
                        )}


                     </div>
                  </motion.div>
               )}
            </AnimatePresence>
            <div className="border-t pt-4 mt-4">
               <div className="flex items-center justify-between font-medium text-base">
                  <span>Ürün Birim Fiyat:</span>
                  <span className="text-primary">
                     {CalculateComissionUnit(item)}
                  </span>
               </div>
            </div>
            <div className="border-t pt-4 mt-4">
               <div className="flex items-center justify-between font-medium text-base">
                  <span>Genel Toplam:</span>
                  <span className="text-primary">
                     {CalculateComission(item)}
                  </span>
               </div>
            </div>
         </Card>
      );
   };

   return (
      <CustomDrawer
         isOpen={drawerProps.open}
         onClose={drawerProps.onClose}
         title="Yeni teklif oluştur - Adım 2"
         extra={
            <div className="flex gap-2">
               <Button
                  color="primary"
                  onClick={() => handlePrev()}
               >
                  Geri
               </Button>
               <Button
                  color="primary"
                  onClick={() => {
                     if (products.length === 0) {
                        message.error('Lütfen en az bir ürün ekleyiniz')
                        return
                     }
                     if (!handleCheckProductValidation()) {
                        return
                     }
                     groupByXmlFirm()
                  }}
               >
                  İleri
               </Button>
            </div>
         }
         {...drawerProps}
      >
         <Drawer
            width={1000}
            footer={null}
            title="Kur Değerleri"
            open={currencyDrawer}
            onClose={() => setCurrencyDrawer(false)}
         >
            <CurrencyRatesDisplay currencies={currencies} />
         </Drawer>
         <Modal
            width={1000}
            footer={null}
            title="Barem Fiyat Düzenle"
            open={otherPriceModal}
            onCancel={() => setOtherPriceModal(false)}
         >
            <div className="p-4">
               {/* Mevcut Barem Fiyatları */}
               <div className="space-y-4 mb-6">
                  <div className="flex items-center justify-between">
                     <h3 className="text-lg font-medium">Barem Fiyatları</h3>
                     <Button
                        color="primary"
                        size="sm"
                        startContent={<IconPlus size={18} />}
                        onClick={() => {
                           let newProducts = _.cloneDeep(products)
                           if (!newProducts[selectedIndex].other_prices) {
                              newProducts[selectedIndex].other_prices = []
                           }
                           newProducts[selectedIndex].other_prices.push({
                              quantity: 0,
                              price: 0
                           })
                           setProducts(newProducts)
                        }}
                     >
                        Yeni Barem Ekle
                     </Button>
                  </div>

                  {/* Barem Fiyat Listesi */}
                  <div className="bg-gray-50 rounded-lg p-4">
                     <div className="space-y-3">
                        {
                           products[selectedIndex]?.other_prices?.map((item: any, index: number) => {
                              return (
                                 <div className="bg-white p-4 rounded-lg shadow-sm">
                                    <div className="grid grid-cols-3 gap-4">
                                       <div>
                                          <label className="block text-sm font-medium text-gray-700 mb-1">
                                             Adet
                                          </label>
                                          <InputNumber
                                             className="w-full"
                                             min={1}
                                             value={item.quantity}
                                             placeholder="Örn: 600"
                                             onChange={(value: any) => {
                                                let newProducts = _.cloneDeep(products)
                                                newProducts[selectedIndex].other_prices[index].quantity = value
                                                setProducts(newProducts)
                                             }}
                                          />
                                       </div>
                                       <div>
                                          <label className="block text-sm font-medium text-gray-700 mb-1">
                                             Birim Fiyat
                                          </label>
                                          <InputNumber
                                             className="w-full"
                                             min={0}
                                             precision={2}
                                             placeholder="Örn: 144.50"
                                             value={item.price}
                                             onChange={(value: any) => {
                                                let newProducts = _.cloneDeep(products)
                                                newProducts[selectedIndex].other_prices[index].price = value
                                                setProducts(newProducts)
                                             }}
                                          />
                                       </div>
                                       <div className="flex items-end">
                                          <Button
                                             color="danger"
                                             variant="light"
                                             onClick={() => {
                                                const newProducts = _.cloneDeep(products)
                                                newProducts[selectedIndex].other_prices = newProducts[selectedIndex].other_prices.filter((item: any, index: number) => index !== index)
                                                setProducts(newProducts)
                                             }}
                                          >
                                             Sil
                                          </Button>
                                       </div>
                                    </div>
                                 </div>

                              )
                           })
                        }
                     </div>
                  </div>
               </div>

               {/* Kaydet Butonu */}
               <div className="flex justify-end gap-2">
                  <Button
                     color="danger"
                     variant="light"
                     onClick={() => setOtherPriceModal(false)}
                  >
                     İptal
                  </Button>
                  <Button
                     color="primary"
                     onClick={() => {
                        let newProducts = _.cloneDeep(products)
                        newProducts[selectedIndex].other_prices = products[selectedIndex].other_prices
                        setProducts(newProducts)
                        setOtherPriceModal(false)
                     }}
                  >
                     Kaydet
                  </Button>
               </div>
            </div>
         </Modal>

         <Modal
            width={1000}
            footer={null}
            title="Birim Maliyet Tekli Kontrolü"
            open={supplierModal}
            onCancel={() => setSupplierModal(false)}
         >
            <Form
               layout="vertical"
               form={supplierForm}
               onFinish={(values: any) => {
                  const supplierForms = suppliers.map(item => {
                     const supplier = {
                        supplier: item.supplier,
                        total: item.total,
                        values: [
                           {
                              offer_price_1: values[`offer_price_1_${item.supplier}`],
                              offer_supplier_1: values[`offer_supplier_1_${item.supplier}`],
                              offer_price_2: values[`offer_price_2_${item.supplier}`],
                              offer_supplier_2: values[`offer_supplier_2_${item.supplier}`],
                              offer_price_3: values[`offer_price_3_${item.supplier}`],
                              offer_supplier_3: values[`offer_supplier_3_${item.supplier}`]
                           }
                        ]
                     }

                     return supplier
                  })

                  dispatch(setOtherSuppliers(supplierForms))
                  setSupplierModal(false)
                  message.success('Tedarikçi teklifleri kaydedildi')
                  dispatch(setProduct(products))
                  handleNext(products)
               }}
            >
               {suppliers?.map((item, index) => {
                  if (item?.supplier) {
                     return (
                        <div>
                           <Alert color="info" className="flex flex-col mb-5">
                              <span>{item?.supplier} </span>
                              <span>{MoneyFormat3(item?.total)} ₺</span>
                           </Alert>
                           <div className="grid grid-cols-2 gap-2 my-2">
                              {/* {JSON.stringify(item)} */}

                              <Form.Item label="2.Birim Maliyet Teklif" name={`offer_price_2_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="2.Tedarikçi Bilgisi" name={`offer_supplier_2_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="3.Birim Maliyet Teklif" name={`offer_price_3_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                              <Form.Item label="3.Tedarikçi Bilgisi" name={`offer_supplier_3_${item.supplier}`}>
                                 <InputRiz />
                              </Form.Item>
                           </div>
                           <Divider />
                        </div>
                     )
                  }
               })}
               <Button color="primary" type="submit">
                  Kaydet
               </Button>
            </Form>
         </Modal>
         <>
            <Form onFinish={handleServiceSave} className="w-full" layout="vertical">
               <Drawer
                  height="100%"
                  placement="bottom"
                  open={servicesDrawer}
                  onClose={() => {
                     serviceForm.submit()
                     setSelectedIndex(null)

                     serviceForm.resetFields()
                     setServicesDrawer(false)

                  }}
               >
                  <Modal
                     footer={null}
                     title="Hizmet Ekle"
                     open={serviceSearchModal}
                     closable
                     onCancel={() => setServiceSearchModal(false)}
                  >

                     <InputRiz
                        prefix={!serviceLoading ? <IconSearch /> : <Loader />}

                        label="Hizmet adını yazın ve enter tuşuna basın"
                        placeholder="Hizmet adını yazın ve enter tuşuna basın"
                        size="lg"

                        onKeyDown={(e: any) => {

                           if (e.keyCode === 13) {

                              handleAddServiceToProduct({
                                 id: Math.random(),
                                 name: e.target.value,
                                 price: 0,
                                 stock: 0,
                                 description: '',
                                 included_in_total: true
                              })
                           }
                           setSearch(e.target.value)
                        }}
                     />
                     {/* <div className="max-h-96 overflow-y-auto">
                        {serviceData?.data?.map((item: any) => {
                           return (
                              <div
                                 key={item.id}
                                 className="flex gap-2 items-center justify-between w-full cursor-pointer h-20 border-b border-gray-100"
                              >
                                 <span className="font-bold">{item.name}</span>
                                 <Button
                                    onClick={() => handleAddServiceToProduct(item)}
                                    color="primary"
                                    startContent={<IconPlus />}
                                    variant="shadow"
                                 >
                                    Ekle
                                 </Button>
                              </div>
                           )
                        })}
                     </div> */}
                  </Modal>
                  <div className="mt-5" ref={drawerFormRef} onScroll={handleScroll}>
                     <Button
                        className="w-full"
                        onClick={() => setServiceSearchModal(true)}
                        color="primary"
                        variant="shadow"
                     >
                        Hizmet Ekle
                     </Button>

                     <div className="mt-5">
                        {products[selectedIndex]?.services.length === 0 && <Empty description="Hizmet bulunamadı" />}

                        <Form form={serviceForm} onFinish={handleServiceSave} className="w-full" layout="vertical">
                           {products[selectedIndex]?.services.length > 0 && (
                              <Button
                                 startContent={<IconConePlus />}
                                 size="lg"
                                 className="w-full"
                                 type="submit"
                                 color="secondary"
                                 variant="ghost"
                              >
                                 Kaydet
                              </Button>
                           )}
                           {products[selectedIndex]?.services?.map((item: any, index: number) => (
                              <Card
                                 key={index}
                                 className="mt-2"
                                 extra={
                                    <div className="flex gap-2">
                                       <Button
                                          onClick={() => {
                                             let newProducts = _.cloneDeep(products)
                                             newProducts[selectedIndex].services = newProducts[
                                                selectedIndex
                                             ].services.filter((service: any, serviceIndex: number) => {
                                                return serviceIndex !== index
                                             })
                                             setProducts(newProducts)
                                          }}
                                          color="danger"
                                          variant="bordered"
                                       >
                                          Sil
                                       </Button>
                                    </div>
                                 }
                              >
                                 <Alert color="info" className="flex flex-col mb-5">
                                    <Form.Item name={`name_${products[selectedIndex].id}_${index}`} label="Hizmet adı">
                                       <InputRiz />
                                    </Form.Item>
                                    <div className="grid grid-cols-4 gap-2 my-2">
                                       <Form.Item label="Birim fiyat" name={`price_${products[selectedIndex].id}_${index}`}>
                                          <InputRiz
                                             onBlur={(e: any) => {
                                                handleReForceServicePrice(index, item.converted_currency, selectedIndex)
                                             }}
                                             type="number" />
                                       </Form.Item>
                                       <Form.Item
                                          label="Para Birimi"
                                          rules={[{ required: true, message: 'Para birimi seçiniz' }]}
                                          name={`currency_${products[selectedIndex].id}_${index}`}
                                       >
                                          <Select
                                             className="w-full"
                                             onChange={(value) => {
                                                handleReForceServicePrice(index, value, selectedIndex)
                                             }}
                                          >
                                             <Select.Option value="">Seçiniz</Select.Option>
                                             <Select.Option value="TRY">TRY</Select.Option>
                                             <Select.Option value="USD">USD</Select.Option>
                                             <Select.Option value="EUR">EUR</Select.Option>
                                             <Select.Option value="GBP">GBP</Select.Option>
                                          </Select>
                                       </Form.Item>

                                       <Form.Item label="Kâr Tipi" name={`commission_type_${products[selectedIndex].id}_${index}`}>
                                          <select

                                             onBlur={(e: any) => {
                                                handleReForceServicePrice(index, item.converted_currency, selectedIndex)
                                             }}
                                             className="w-full select-item p-2 border border-gray-300 rounded-md">
                                             <option value="3">Seçim Yapın</option>
                                             <option value="1">Sabit</option>
                                             <option value="2">Yüzde</option>
                                          </select>
                                       </Form.Item>
                                       <Form.Item label="Kâr" name={`commission_${products[selectedIndex].id}_${index}`}>
                                          <InputRiz
                                             onBlur={(e: any) => {
                                                handleReForceServicePrice(index, item.converted_currency, selectedIndex)
                                             }}
                                             type="number" />
                                       </Form.Item>
                                    </div>

                                    <Form.Item
                                       label="Birim Maliyete Dahil Et"
                                       name={`included_in_total_${products[selectedIndex].id}_${index}`}
                                       valuePropName="checked"
                                    >

                                       <Switch
                                          size="sm"
                                          checked={item.included_in_total}
                                          onChange={(e: any) => {
                                             let newProducts = _.cloneDeep(products)
                                             newProducts[selectedIndex].services[index].included_in_total = e
                                             setProducts(newProducts)
                                          }}
                                       />
                                    </Form.Item>
                                    <Form.Item name={`description_${products[selectedIndex].id}_${index}`} label="Açıklama">
                                       <QuillEditor
                                          style={{ height: 300 }}
                                          value={item.description}
                                          modules={modules}
                                          formats={formats}
                                          onChange={(value: any) => {
                                             const plainText = getPlainTextFromHtml(value);
                                             let newProducts = _.cloneDeep(products)
                                             newProducts[selectedIndex].services[index].description = plainText
                                             setProducts(newProducts)
                                          }}
                                       />
                                    </Form.Item>
                                 </Alert>
                              </Card>
                           ))}
                        </Form>
                     </div>
                  </div>
               </Drawer>
            </Form>
            <div>
               <Drawer
                  placement="bottom"
                  height={window.innerHeight - 100}
                  footer={null}
                  title="Yeni Ürün Ekle"
                  open={open}
                  closable
                  onClose={() => setOpen(false)}
               >
                  <Products
                     currentProducts={products}
                     setOfferProduct={setProducts}
                     isFromOffer={true}
                     handleClose={setOpen}
                  />
               </Drawer>
            </div>
            <Drawer
               open={addProductModal}
               onClose={() => setAddProductModal(false)}
               placement="bottom"
               height="100%"
               title="Yeni Ürün Ekle"
               extra={
                  <Button
                     onClick={() => {
                        addProductForm.submit()
                     }}
                     color="primary"
                  >
                     Kaydet
                  </Button>
               }
            >
               <Card>
                  <Form
                     layout="vertical"
                     form={addProductForm}
                     onFinish={(values: any) => {
                        const findProduct = products.find(item => item.id === values.id)
                        if (findProduct) {
                           message.error('Bu ürün zaten ekli')
                           return
                        }

                        let productArray = {
                           id: values.model,
                           name: values.name,
                           price: parseFloat(values.price).toFixed(2),
                           stock: values.stock,
                           product_qty: values.stock,
                           delivery_date: '',
                           commission: 0,
                           commission_type: '3',
                           description: values.description,
                           services: []
                        }

                        setProducts([...products, productArray])
                        setOpen(false)
                        setAddProductModal(false)
                     }}
                  >
                     <Form.Item
                        label="Ürün Adı"
                        name="name"
                        rules={[{ required: true, message: 'Lütfen bir ürün adı giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item
                        label="Ürün Stok Kodu"
                        name="model"
                        rules={[{ required: true, message: 'Lütfen  stok kodu giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item
                        label="Ürün Fiyatı"
                        name="price"
                        rules={[{ required: true, message: 'Lütfen bir ürün fiyatı giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item
                        label="Stok"
                        name="stock"
                        rules={[{ required: true, message: 'Lütfen bir stok miktarı giriniz' }]}
                     >
                        <InputRiz />
                     </Form.Item>
                     <Form.Item label="Açıklama" name="description">
                        {/* <Textarea /> */}
                        <QuillEditor
                           style={{ height: 300 }}
                           value={''}
                           onChange={(value: any) => {

                           }}
                        />
                     </Form.Item>
                     <Form.Item label="Görsel" name="image">
                        <Upload
                           onChange={e => {
                              setProductImage(e.file)
                           }}
                           accept="image/*"
                           name="image"
                           listType="picture"
                           className="upload-list-inline"
                        >
                           <Button>Görsel Yükle</Button>
                        </Upload>
                     </Form.Item>
                  </Form>
               </Card>
            </Drawer>
            <div className="@container">
               <Card
                  title="Teklif Detayları"
                  extra={
                     <div className="gap-2">
                        <Button
                           onClick={() => {
                              setOpen(true)
                           }}
                           color="primary"
                           variant="shadow"
                        >
                           Ürün Ekle
                        </Button>
                     </div>
                  }
               >

               </Card>
               <Form form={productForm} layout="vertical" className="">
                  <div className="mt-5 space-y-2" ref={bodyRef}>
                     <Button color="primary" onClick={() => setCurrencyDrawer(true)} className='ml-5'>Kur Değerleri</Button>
                     {products.map((item: any, index: number) => {
                        return (
                           <Card
                              className="shadow-lg"
                              title={
                                 <div className="flex gap-2 items-center">
                                    <Image
                                       width={50}
                                       src={item.image}
                                       alt="product"
                                       className="w-10 h-10 object-cover rounded-full"
                                    />
                                    <div className="flex flex-col">
                                       <span>
                                          Ürün Adı <span className="font-bold"> {item.name}</span>
                                       </span>
                                       <span>
                                          Stok Kodu <span className="font-bold"> {item.model}</span>
                                       </span>
                                    </div>
                                 </div>
                              }
                              key={index}
                              extra={
                                 <div className="gap-2  grid grid-cols-3">
                                    <Button
                                       onClick={() => {
                                          findIndexProductId(item.id)
                                          setServicesDrawer(true)
                                       }}
                                       color="secondary"
                                       startContent={<IconCubePlus />}
                                       variant="bordered"
                                    >
                                       Hizmet Ekle / Düzenle
                                    </Button>
                                    <Button
                                       className="w-full"
                                       onClick={() => {
                                          setOtherPriceModal(true)
                                          setSelectedIndex(index)
                                       }}
                                       color="primary"
                                       variant="shadow"
                                    >
                                       Barem Fiyat Düzenle
                                    </Button>
                                    <Button
                                       onClick={() => {
                                          setProducts(products.filter((_, i) => i !== index))
                                          dispatch(setProduct(products.filter((_, i) => i !== index)))
                                       }}
                                       color="danger"
                                       startContent={<IconTrash />}
                                       variant="bordered"
                                    >
                                       Sil
                                    </Button>
                                 </div>
                              }
                           >
                              <div>
                                 <Alert color="info" className="flex flex-col mb-5">
                                    <PriceCalculationCard item={item} />
                                 </Alert>
                              </div>

                              <div className="grid grid-cols-6 gap-5">

                                 <div>
                                    <InputRiz
                                       label={`Maliyet Birim Fiyat (${item.currency})`}
                                       onChange={e => {
                                          onChangeProductInput(index, 'price', e.target.value)
                                       }}
                                       value={item.price}
                                       type="number"
                                    />
                                    {RenderValidatioError(index, 'price')}
                                 </div>
                                 <div>
                                    <CurrencySelector
                                       item={item}
                                       index={index}
                                       onChangeProductInput={onChangeProductInput}
                                    />
                                 </div>
                                 <div>
                                    {/* <Alert variant="flat" color="danger" className="text-center">
                                                        Bu ürünün stoğu {item.stock} {item.product_qty} <b>{item.supplier}</b> firmasına aittir Değişiklik yaparken bilgi alınız
                                                    </Alert> */}

                                    <InputRiz
                                       label="Adet"
                                       onChange={e => handleStockChange(e, index, item)}
                                       onBlur={(e: any) => {
                                          handleStockChange(e, index, item);
                                       }}
                                       value={item.stock}
                                       type="text"

                                    />
                                    {RenderValidatioError(index, 'stock')}

                                    {item.supplier == 'gimel' && parseInt(item.stock) > parseInt(item.product_qty) && (
                                       <Alert size="sm" variant="flat" color="danger" className="text-center">
                                          Bu ürünün stoğu <b>{item.supplier}</b> firmasına aittir Değişiklik yaparken
                                          bilgi alınız
                                       </Alert>
                                    )}
                                 </div>
                                 <div>
                                    <InputRiz
                                       label="Maximum Teslim Günü"
                                       onChange={e => {
                                          onChangeProductInput(index, 'delivery_date', e.target.value)
                                       }}
                                       placeholder='Gün Sayısı Giriniz'
                                       value={item.delivery_date}
                                       type="number"
                                    />
                                    {RenderValidatioError(index, 'delivery_date')}
                                 </div>

                                 <div>
                                    <label className="block text-sm font-medium text-gray-700">Kâr Tipi</label>


                                    <select
                                       className="w-full p-2 select-item border border-gray-300 rounded-md"
                                       name="commission_type"
                                       id={`commission_type_${index}`}
                                       onChange={e => {
                                          onChangeProductInput(index, 'commission_type', e.target.value)
                                       }}
                                    >
                                       <option selected={item.commission_type == "3"} value="3">
                                          Seçim Yapın
                                       </option>
                                       <option selected={item.commission_type == "1"} value="1">
                                          Yüzde
                                       </option>
                                       <option selected={item.commission_type == "2"} value="2">
                                          Sabit
                                       </option>
                                    </select>

                                    {RenderValidatioError(index, 'commission_type')}
                                 </div>
                                 <div>
                                    <InputRiz
                                       label={'Kâr ' + item.converted_currency}
                                       type="number"
                                       onChange={e => {
                                          onChangeProductInput(index, 'commission', e.target.value)
                                       }}
                                       value={item.commission}
                                    />
                                    {RenderValidatioError(index, 'commission')}
                                 </div>
                              </div>
                              <div className="grid grid-cols-2 gap-5">
                                 <Form.Item label="Açıklama">
                                    <RichTextEditor
                                       totalServices={item?.services?.length}
                                       value={item.description}
                                       onChange={(value: any) => {
                                          onChangeProductInput(index, 'description', value)
                                       }}
                                    />
                                 </Form.Item>
                                 <div className="w-full">
                                    <Form layout='vertical'>
                                       <div className="space-y-4">
                                          {/* Giriş Alanları */}
                                          <div className="grid grid-cols-2 gap-3 items-center">
                                             <Form.Item label="Satış Fiyatı" className="mb-0">
                                                <InputNumber
                                                   className="w-full"
                                                   placeholder="0.00"
                                                   precision={2}
                                                   prefix={item.converted_currency}
                                                   value={item.sales_price}
                                                   onChange={(value) => {
                                                      onChangeProductInput(index, 'sales_price', value)
                                                   }}

                                                />
                                             </Form.Item>
                                          </div>

                                          {/* Dönüşüm Sonuçları */}
                                          <div className="bg-gray-50 rounded-lg p-3">
                                             <Table
                                                size="small"
                                                pagination={false}
                                                showHeader={false}
                                                dataSource={currencies.filter((item: any) => item.currency.includes('-TRY')).map((item: any) => ({
                                                   currency: item.currency,
                                                   value: item.rate
                                                }))}
                                                columns={[
                                                   {
                                                      dataIndex: 'currency',
                                                      render: (text) => (
                                                         <div className="flex items-center gap-2">
                                                            <IconCurrency />
                                                            <span>{text}</span>
                                                         </div>
                                                      ),
                                                   },
                                                   {
                                                      dataIndex: 'value',
                                                      align: 'right' as const,
                                                      render: (text) => (
                                                         <span className="font-medium">{text}</span>
                                                      ),
                                                   },
                                                ]}
                                             />
                                          </div>


                                          {/* Kâr Hesaplama */}

                                          <Button
                                             onClick={() => {
                                                const product_price = item.price;
                                                const sales_price = item.sales_price;

                                                // onChangeProductInput(index, 'comission', sales_price - product_price)


                                                onChangeProductInput(index, 'commission_type', "2", true); // Doğrudan state'i güncelle



                                             }}
                                             color="primary" className="w-full">
                                             Hesapla
                                          </Button>

                                          {/* Toplam */}
                                       </div>
                                    </Form>
                                 </div>
                              </div>

                              {/* <div className="mt-2">
                                 {item.services.length > 0 && (
                                    <div>
                                       <Alert color="info">
                                          Hizmet Toplamı: {item.services.length > 0 ? item.services.length : 0}
                                       </Alert>

                                       <table className="w-full">
                                          <thead>
                                             <tr>
                                                <th className="text-left p-2">Hizmet Adı</th>
                                                <th className="text-left p-2">Fiyat</th>
                                                <th className="text-left p-2">Kâr</th>
                                                <th className="text-left p-2">Kâr Tipi</th>
                                                <th className="text-left p-2">Açıklama</th>
                                                <th className="text-left p-2">Birim Fiyat Dahil mi?</th>
                                                <th className="text-left p-2">Düzenle</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                             {item.services.map((service: any, serviceIndex: number) => {
                                                return (
                                                   <tr key={serviceIndex}>
                                                      <td className="p-2">{service.name}</td>
                                                      <td className="p-2">
                                                         {service.included_in_total ? (
                                                            <div>
                                                               <span>{MoneyFormat(service.original_price, service.original_currency)}</span>
                                                               {service.original_currency !== item.currency && (
                                                                  <span className="text-gray-500 text-sm ml-2">
                                                                     ({MoneyFormat(service.price, item.currency)})
                                                                  </span>
                                                               )}
                                                            </div>
                                                         ) : (
                                                            <span>{MoneyFormat(service.original_price, service.original_currency)}</span>
                                                         )}
                                                      </td>
                                                      <td className="p-2">{service.commission}</td>
                                                      <td className="p-2">
                                                         {service.commission_type === '1' ? 'Yüzde Kâr' :
                                                            service.commission_type === '2' ? 'Sabit Kâr' : 'Kâr Yok'}
                                                      </td>
                                                      <td className="p-2">{service.description}</td>
                                                      <td className="p-2">
                                                         <Chip
                                                            size="sm"
                                                            color={service.included_in_total ? "success" : "default"}
                                                         >
                                                            {service.included_in_total ? 'Evet' : 'Hayır'}
                                                         </Chip>
                                                      </td>
                                                      <td className="p-2">
                                                         <Button
                                                            color="primary"
                                                            size="sm"
                                                            onClick={() => {
                                                               findIndexProductId(item.id);
                                                               setServicesDrawer(true);
                                                            }}
                                                         >
                                                            Düzenle
                                                         </Button>
                                                      </td>
                                                   </tr>
                                                )
                                             })}
                                          </tbody>
                                       </table>
                                    </div>
                                 )}
                              </div> */}

                              {(item.supplier == 'gimel' || item.supplier == 'Gimel') && (
                                 <div className="grid grid-cols-1 mt-20">
                                    <Form.Item

                                       valuePropName="checked"
                                       name={`isReserve_${item.id}`}
                                    >
                                       <Switch
                                          label="Stoğu Rezerve Et"
                                          onChange={e => {
                                             onChangeProductInput(index, 'isReserve', e.target.value == "on" ? true : false)
                                          }}
                                          defaultChecked={item.isReserve}
                                       />
                                    </Form.Item>
                                    {productForm.getFieldValue(`isReserve_${item.id}`) && (
                                       <Alert color="info">
                                          Bu ürün şu an {moment().format('DD.MM.YYYY')} - {moment().add(3, 'days').format('DD.MM.YYYY')} tarihleri arasında rezerve edilmiştir.
                                       </Alert>
                                    )}
                                 </div>
                              )}
                           </Card>
                        )
                     })}
                  </div>
               </Form>
            </div>
         </>
         <Modal
            open={reserveCheckModal}
            onCancel={() => {
               setReserveCheckModal(false);
               setCheckingProduct(null);
            }}
            title={
               <div className="flex items-center gap-2 text-warning">
                  <IconInfoCircle size={20} />
                  <span>Rezervasyon Uyarısı</span>
               </div>
            }
            footer={[
               <Button
                  key="cancel"
                  variant="light"
                  onClick={() => {
                     setReserveCheckModal(false);
                     setCheckingProduct(null);
                  }}
               >
                  İptal
               </Button>,
               <Button
                  key="continue"
                  color="primary"
                  onClick={() => {
                     if (checkingProduct) {
                        onChangeProductInput(checkingProduct.index, 'stock', checkingProduct.newStock);
                     }
                     setReserveCheckModal(false);
                     setCheckingProduct(null);
                  }}
               >
                  Yine de Değiştir
               </Button>
            ]}
         >
            <div className="p-4">
               <Alert
                  color="warning"
                  className="mb-4"
               >
                  <div className="space-y-2">
                     <p className="font-medium">Bu ürün rezerve edilmiş!</p>
                     <p>
                        Bu ürün şu an <span className="font-semibold text-warning">{reserveUserName}</span> tarafından rezerve edilmiş durumda.
                        Stok değişikliği yapmadan önce lütfen ilgili kişiyle iletişime geçin.
                     </p>
                  </div>
               </Alert>

               {checkingProduct && (
                  <div className="space-y-4">
                     {/* Ürün Detayları */}
                     <div className="p-4 bg-gray-50 rounded-lg">
                        <div className="text-sm text-gray-600 mb-2">Ürün Bilgileri</div>
                        <div className="font-medium">{checkingProduct.name}</div>
                        <div className="text-sm text-gray-500">Stok Kodu: {checkingProduct.model}</div>
                     </div>

                     {/* Rezervasyon Detayları */}
                     <div className="p-4 bg-blue-50 rounded-lg">
                        <div className="text-sm text-blue-600 mb-2">Rezervasyon Detayları</div>
                        <div className="space-y-2">
                           <div className="flex items-center justify-between text-sm">
                              <span className="text-gray-600">Rezervasyon Tarihi:</span>
                              <span className="font-medium">
                                 {moment(checkingProduct.reserveDate).format('DD.MM.YYYY')}
                              </span>
                           </div>
                           <div className="flex items-center justify-between text-sm">
                              <span className="text-gray-600">Rezerve Edilen Miktar:</span>
                              <span className="font-medium">{checkingProduct.reservedQty} Adet</span>
                           </div>
                        </div>
                     </div>

                     {/* Stok Değişikliği */}
                     <div className="p-4 bg-green-50 rounded-lg">
                        <div className="text-sm text-green-600 mb-2">Talep Edilen Değişiklik</div>
                        <div className="flex items-center gap-3">
                           <div className="flex-1 text-center p-2 bg-white rounded border border-gray-200">
                              <div className="text-xs text-gray-500 mb-1">Mevcut Stok</div>
                              <div className="font-medium">{checkingProduct.stock}</div>
                           </div>
                           <IconChevronRight size={16} className="text-gray-400" />
                           <div className="flex-1 text-center p-2 bg-white rounded border border-gray-200">
                              <div className="text-xs text-gray-500 mb-1">Yeni Stok</div>
                              <div className="font-medium">{checkingProduct.newStock}</div>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
            </div>
         </Modal>
         <style>{`
            .ql-snow .ql-picker.ql-size .ql-picker-label::before,
            .ql-snow .ql-picker.ql-size .ql-picker-item::before {
               content: attr(data-value) !important;
            }
         `}</style>
      </CustomDrawer>
   )
}
