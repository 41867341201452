import { IconArrowsExchange2, IconTrendingDown, IconClock } from "@tabler/icons-react";

import { IconTrendingUp } from "@tabler/icons-react";

import { IconCurrencyLira } from "@tabler/icons-react";

import { IconCurrencyPound } from "@tabler/icons-react";

import { IconCurrencyDollar } from "@tabler/icons-react";

import { IconCurrencyEuro } from "@tabler/icons-react";
import { motion } from "framer-motion";

export const CurrencyRatesDisplay = ({ currencies }: { currencies: any[] }) => {
  const mainCurrencies = ['TRY', 'USD', 'EUR', 'GBP'];

  // Kur matrisini oluştur
  const rateMatrix = mainCurrencies.map(from => ({
    from,
    rates: mainCurrencies.map(to => ({
      to,
      rate: currencies.find(c => c.currency === `${from}-${to}`)?.rate || 1
    }))
  }));

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      className="p-4 bg-white rounded-xl shadow-sm border border-gray-100"
    >
      {/* Ana Para Birimleri Grid */}
      <div className="grid grid-cols-4 gap-6">
        {mainCurrencies.map((currency, idx) => (
          <div key={currency} className="space-y-4">
            {/* Para Birimi Başlığı */}
            <div className="flex items-center gap-3 p-3 bg-gray-50/50 rounded-xl">
              <div className="w-10 h-10 rounded-lg bg-primary/5 flex items-center justify-center">
                <CurrencyIcon currency={currency} />
              </div>
              <div>
                <h3 className="font-medium text-gray-900">{currency}</h3>
                <p className="text-xs text-gray-500">Güncel Kurlar</p>
              </div>
            </div>

            {/* Kur Kartları */}
            <div className="space-y-3">
              {mainCurrencies.filter(c => c !== currency).map(toCurrency => {
                const rate = rateMatrix[idx].rates.find(r => r.to === toCurrency)?.rate || 1;
                const isUp = rate > 1;

                return (
                  <motion.div
                    key={`${currency}-${toCurrency}`}
                    whileHover={{ scale: 1.02 }}
                    className="p-3 bg-white rounded-lg border border-gray-100 hover:border-primary/20 hover:shadow-sm transition-all duration-200"
                  >
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center gap-2">
                        <CurrencyIcon currency={toCurrency} />
                        <span className="text-sm text-gray-600">{toCurrency}</span>
                      </div>
                    </div>

                    <div className="flex items-end justify-between">
                      <div>
                        <div className="text-lg font-semibold text-gray-900">
                          {Number(rate).toFixed(4)}
                        </div>
                        <div className="text-xs text-gray-500">
                          1 {currency} = {Number(rate).toFixed(4)} {toCurrency}
                        </div>
                      </div>
                      <div className="flex items-center gap-1 text-xs text-gray-400">
                        <IconClock size={12} />
                        Güncel
                      </div>
                    </div>

                    {/* Mini Grafik Placeholder */}
                  </motion.div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

// Para birimi ikonları
const CurrencyIcon = ({ currency }: { currency: string }) => {
  const icons = {
    USD: <IconCurrencyDollar className="w-5 h-5 text-green-600" />,
    EUR: <IconCurrencyEuro className="w-5 h-5 text-blue-600" />,
    GBP: <IconCurrencyPound className="w-5 h-5 text-purple-600" />,
    TRY: <IconCurrencyLira className="w-5 h-5 text-red-600" />
  };
  return icons[currency as keyof typeof icons] || null;
};

// Trend göstergesi
