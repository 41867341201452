import { Button, Card, CardHeader, CardBody, Popover, PopoverTrigger, PopoverContent } from '@nextui-org/react'
import { IconCalendar, IconMail, IconMapPin, IconPdf } from '@tabler/icons-react'
import { Drawer, TableColumnProps, Tag, message } from 'antd'
import TableComponents from 'components/Table'
import { createUid } from 'hooks/use-id'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store'
import { Input, Switch, Textarea } from 'rizzui'
import { addNote, setCC, setCustomContent, setSendMail } from 'features/user/offer'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { Modal as AntModal } from 'antd'
import mail from 'utils/mail'
import useFetch from 'hooks/use-fetch'
import { OFFER_MANAGEMENT_STORE } from 'api/offer-management'
import { Chip } from '@nextui-org/react'
import axios from 'axios'
import { MoneyFormat } from 'hooks/currency'

function Step3() {
   const [mailContent, setMailContent] = React.useState('')
   const [termTime, setTermTime] = React.useState('')
   const [deliveryAddress, setDeliveryAddress] = React.useState('')
   const offer = useSelector((state: RootState) => state.offer)
   const [isOpenModal, setIsOpenModal] = React.useState(false)
   const dispatch = useDispatch()
   const [ccModal, setCcModal] = React.useState(false)
   const [noteModal, setNoteModal] = React.useState(false)
   const [cc, setCc] = React.useState(offer.cc)
   const [note, setNode] = useState('')
   const [previewOffer, { loading, data, error }] = useFetch<any>()
   const [previewModal, setPreviewModal] = useState(false)
   const [currencies, setCurrencies] = useState<any>([])
   const [pdfUrl, setPdfUrl] = useState('')

   useEffect(() => {


      const fetchCurrencies = async () => {
         const response = await axios.get('/get-currency');
         setCurrencies(response.data);
      }

      fetchCurrencies();
   }, [])

   const colums: TableColumnProps<any>[] = [
      {
         key: 'product',
         title: 'Ürün Adı (Birim Fiyat)',
         dataIndex: 'name',
         render: (text, record) => {
            let merge = (record.currency ?? 'TRY') + '-' + (record.converted_currency ?? 'TRY')
            let currency = currencies.find((item: any) => item.currency == merge) ?? 'TRY'
            let split = merge.split('-')
            return (
               <div className="flex flex-col gap-1">
                  <span className="font-medium">{text}</span>
                  <div className="flex items-center gap-2 text-sm text-gray-500">
                     <span>Birim Fiyat:</span>
                     <span className="font-medium text-primary">
                        {MoneyFormat(record.price * currency?.rate, split[1] ?? 'TRY')}
                     </span>
                  </div>
               </div>
            )
         }
      },
      {
         key: 'quantity',
         title: 'Adet / Miktar',
         dataIndex: 'stock',
         render: (text, record) => (
            <div className="flex flex-col gap-1">
               <span className="font-medium">{text} Adet</span>
               <span className="text-sm text-gray-500">

               </span>
            </div>
         )
      },
      {
         key: 'delivery_date',
         title: 'Teslim Tarihi',
         dataIndex: 'delivery_date',
         render: (text) => (
            <div className="flex items-center gap-2">
               <IconCalendar size={16} className="text-gray-400" />
               <span>{text} Gün İçinde</span>
            </div>
         )
      },
      {
         key: 'commission',
         title: 'Kâr Oranı  (Kâr Tipi)',
         dataIndex: 'commission',
         render: (text, record) => {
            return (
               <div className="flex flex-col gap-1">
                  <div className="flex items-center gap-2">
                     <span className="font-medium">{text}</span>
                     <Chip size="sm" color={record.commission_type == 1 ? "primary" : "secondary"}>
                        {record.commission_type == 1 ? 'Yüzde' : 'Sabit'}
                     </Chip>
                  </div>
                  <span className="text-sm text-gray-500">
                     Kar: {record.commission_type == 1 ?
                        `${text}%` :
                        `${text} ${offer.currency}`
                     }
                  </span>
               </div>
            )
         }
      },
      {
         key: 'Baremli Fiyat Var mı?',
         title: 'Baremli Fiyat Var mı?',
         dataIndex: 'other_prices',
         render: (text, record: any) => {
            return (
               <div className="flex flex-col gap-1">
                  <span className="font-medium">{record?.other_prices?.length > 0 ? 'Evet (' + record?.other_prices?.length + ' adet)' : 'Hayır'}</span>
               </div>
            )
         }
      }
   ]

   const addCc = () => {
      dispatch(setCustomContent(mail(mailContent)))
      setIsOpenModal(false)
   }

   useEffect(() => {
      if (data) {
         setPdfUrl(data.pdf_path)
         setPreviewModal(true)
      }
   }, [data])

   return (
      <div className="space-y-2">
         <Drawer
            placement="bottom"
            open={previewModal}
            height={window.innerHeight}
            onClose={() => {
               setPreviewModal(false)
            }}
         >
            <iframe src={pdfUrl} width="100%" height="100%"></iframe>
         </Drawer>
         <AntModal
            title="Mail İçeriği"
            open={isOpenModal}
            onCancel={() => {
               setIsOpenModal(false)
            }}
            width={1200}
            footer={[
               <Button
                  key="cancel"
                  color="danger"
                  variant="light"
                  onClick={() => setIsOpenModal(false)}
               >
                  Kapat
               </Button>,
               <Button
                  key="submit"
                  color="primary"
                  onClick={() => {
                     dispatch(setCustomContent(mail(mailContent)))
                     setIsOpenModal(false)
                  }}
               >
                  Kaydet
               </Button>
            ]}
         >
            <div className="">
               <ReactQuill
                  style={{ height: 700 }}
                  value={mailContent}
                  onChange={(value: any) => setMailContent(value)}
               />
            </div>
         </AntModal>

         <Card>
            <CardBody>
               <div className="flex gap-2  justify-between">
                  <div className="flex flex-col space-y-2">
                     <span className="flex gap-2">
                        <span className="font-bold text-md">Teklif No:</span>
                        <span>{offer.offerNumber}</span>
                     </span>
                     <span className="flex gap-2 items-center">
                        <span className="font-bold text-md">Cc:</span>
                        <div className="flex flex-wrap gap-2">
                           {offer.cc ?
                              offer.cc.split(';').map((email: string, index: number) => (
                                 email.trim() && (
                                    <Chip
                                       key={index}
                                       size="sm"
                                       variant="flat"
                                       color="primary"
                                    >
                                       {email.trim()}
                                    </Chip>
                                 )
                              ))
                              :
                              <span className="text-gray-500">CC eklenmemiş</span>
                           }
                        </div>
                     </span>
                     <span className="flex gap-2">
                        <IconMapPin size={20} />
                        <span>{offer.contactDetail?.name}</span>
                     </span>
                     {/* <span className='flex gap-2'>
                                <span className='font-bold text-md'>
                                    UUID
                                </span>
                                <span>
                                    {createUid()}
                                </span>
                            </span> */}
                     <span className="flex gap-2">
                        <span className="font-bold text-md flex gap-2">
                           <IconCalendar size={20} />
                           <span>Tarih</span>
                        </span>
                        <span>{new Date().toLocaleDateString()}</span>
                     </span>
                     <div className="flex flex-col gap-2">
                        <Switch
                           onChange={() => {
                              dispatch(setSendMail(!offer.sendMail))
                           }}
                           checked={offer.sendMail}
                           label="Teklifi mail olarak gönder"
                           size="xl"
                        />
                        <Button
                           style={{
                              display: offer.sendMail ? 'block' : 'none'
                           }}
                           color="primary"
                           variant="solid"
                           onPress={() => {
                              setIsOpenModal(true)
                           }}
                        >
                           Mail İçeriğini Düzenle
                        </Button>
                     </div>
                     <Button color="primary" onClick={() => setCcModal(true)}>
                        Cc Ekle
                     </Button>

                     <AntModal
                        title="Mail CC Ekle"
                        open={ccModal}
                        onCancel={() => setCcModal(false)}
                        footer={[
                           <Button
                              key="cancel"
                              color="danger"
                              variant="light"
                              onClick={() => setCcModal(false)}
                           >
                              İptal
                           </Button>,
                           <Button
                              key="submit"
                              color="primary"
                              onClick={() => {
                                 dispatch(setCC(cc as any))
                                 setCcModal(false)
                                 message.success('CC başarıyla eklendi')
                              }}
                           >
                              Kaydet
                           </Button>
                        ]}
                     >
                        <div className="flex flex-col gap-4">
                           <p className="text-sm text-gray-600">
                              Birden fazla mail adresi eklemek için ; (noktalı virgül) kullanabilirsiniz.
                              <br />
                              Örnek: mail1@domain.com;mail2@domain.com
                           </p>

                           <Textarea
                              value={cc}
                              onChange={e => {
                                 setCc(e.target.value)
                              }}
                              placeholder="Mail adreslerini giriniz"
                              rows={4}
                           />
                        </div>
                     </AntModal>
                     <Popover
                        size="lg"
                        placement="bottom"
                        showArrow
                        offset={10}
                        isOpen={noteModal}
                        onOpenChange={open => setNoteModal(open)}
                     >
                        <PopoverTrigger>
                           <Button color="primary">Not Ekle</Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-[400px]">
                           {titleProps => (
                              <div className="px-1 py-2 w-full">
                                 <p className="text-small font-bold text-foreground" {...titleProps}>
                                    Notunuz
                                 </p>
                                 <div className="mt-2 flex flex-col gap-2 w-full">
                                    <Textarea
                                       value={note}
                                       onChange={e => {
                                          setNode(e.target.value)
                                       }}
                                       placeholder="Notunuz"
                                    />
                                    <Button
                                       color="primary"
                                       className="mt-2"
                                       onClick={() => {
                                          dispatch(addNote(note))
                                          setNoteModal(false)
                                          message.success('Notunuz Eklendi')
                                       }}
                                    >
                                       Kaydet
                                    </Button>
                                 </div>
                              </div>
                           )}
                        </PopoverContent>
                     </Popover>
                  </div>
                  <div className="flex gap-2">
                     <Button
                        onClick={() => {
                           setPdfUrl('')
                           //@ts-ignore
                           previewOffer(OFFER_MANAGEMENT_STORE, {
                              endPoint: 'preview-offer',
                              body: {
                                 offer_number: offer.offerNumber,
                                 products: offer.products,
                                 currency: offer.currency,
                                 term_time: offer.term_time
                              }
                           })
                        }}
                        startContent={<IconPdf size={20} />}
                        color="primary"
                        variant="solid"
                        isLoading={loading}
                     >
                        Teklifi Önizle
                     </Button>
                  </div>
               </div>
            </CardBody>
         </Card>
         <Card title="Ürünler">
            <CardBody>
               <TableComponents columns={colums} data={offer.products} pagination={false} loading={false} />
            </CardBody>
         </Card>
      </div>
   )
}

export default Step3
