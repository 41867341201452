import React, { useState } from 'react'
import { Button } from '@nextui-org/react'
import { IconEdit, IconTrash, IconPhone, IconMail, IconBriefcase, IconDotsVertical, IconSearch, IconPlus, IconFilePlus } from '@tabler/icons-react'
import { Dropdown, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import { Input } from 'rizzui'
import { setContactId } from 'features/user/offer'
import { routes } from 'config/routes'
import { useNavigate } from 'react-router-dom'
import CreateRequestModal from 'components/modals/CreateRequestModal'
import { openRequestModal } from 'features/modals/requestModal'

interface Contact {
   id: number;
   name?: string;
   email?: string;
   phone?: string;
   title?: string;
   customer_id?: number;
}

interface ContactCardProps {
   contacts: Contact[];
   onClose: () => void;
   onEdit: (contact: Contact) => void;
   onDelete: (contactId: number) => void;
   onAdd: () => void;
}

const ContactCard = ({ contacts, onClose, onEdit, onDelete, onAdd }: ContactCardProps) => {
   const [searchTerm, setSearchTerm] = useState('');
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const user = JSON.parse(localStorage.getItem('user') as any);
   const [createRequestModal, setCreateRequestModal] = useState(false);

   const filteredContacts = contacts.filter(contact =>
      contact.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      contact.title?.toLowerCase().includes(searchTerm.toLowerCase())
   );

   const handleDelete = (contactId: number) => {
      onDelete(contactId);
   };

   const handleCreateOffer = (contact: Contact) => {
      if (user?.role === 'admin') {
         dispatch(openRequestModal({ contactId: contact.id, productsFromCart: false, userId: null }));
      } else {
         dispatch(setContactId(contact.id as any));
         navigate(routes.offerManagement.add);
      }
   };

   return (
      <div className="space-y-6 p-4">
         <div className="sticky top-0 bg-white pb-4 z-10 space-y-4">
            <div className="flex justify-between items-center">
               <h2 className="text-xl font-semibold text-gray-800">Firma Kişileri</h2>
               <Button
                  color="primary"
                  variant="flat"
                  startContent={<IconPlus className="w-4 h-4" />}
                  onClick={onAdd}
               >
                  Yeni Kişi Ekle
               </Button>
            </div>

            <Input
               type="text"
               placeholder="İsim, e-posta veya telefon ile arayın..."
               value={searchTerm}
               onChange={(e) => setSearchTerm(e.target.value)}
               prefix={<IconSearch className="h-4 w-4 text-gray-400" />}
               clearable
               size="lg"
            />
         </div>

         <div className="space-y-4">
            {filteredContacts.map((contact) => (
               <div
                  key={contact.id}
                  className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow duration-200"
               >
                  <div className="p-6">
                     <div className="flex justify-between items-start mb-4">
                        <div className="flex items-center gap-4">
                           <div className="w-12 h-12 rounded-full bg-gradient-to-br from-primary/10 to-primary/20 flex items-center justify-center">
                              <span className="text-lg font-semibold text-primary">
                                 {contact.name?.charAt(0).toUpperCase() || '?'}
                              </span>
                           </div>
                           <div>
                              <h3 className="text-lg font-semibold text-gray-800">{contact.name}</h3>
                              <span className="inline-block px-3 py-1 bg-primary/10 text-primary text-sm rounded-full mt-1">
                                 {contact.title}
                              </span>
                           </div>
                        </div>
                        <Dropdown
                           menu={{
                              items: [
                                 {
                                    key: 'edit',
                                    label: 'Düzenle',
                                    icon: <IconEdit className="w-4 h-4" />,
                                    onClick: () => onEdit(contact)
                                 },
                                 {
                                    key: 'edit',
                                    label: 'Tanıtım Maili Gönder',
                                    icon: <IconMail className="w-4 h-4" />,
                                    onClick: () => onEdit(contact)
                                 },
                                 {
                                    key: 'delete',
                                    label: 'Sil',
                                    icon: <IconTrash className="w-4 h-4" />,
                                    danger: true,
                                    onClick: () => handleDelete(contact.id)
                                 }
                              ]
                           }}
                        >
                           <Button
                              isIconOnly
                              variant="light"
                              className="text-gray-500"
                           >
                              <IconDotsVertical className="w-5 h-5" />
                           </Button>
                        </Dropdown>
                     </div>

                     <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div className="flex items-center gap-3 p-3 rounded-lg bg-gray-50/50">
                           <div className="w-10 h-10 rounded-lg bg-blue-500/10 flex items-center justify-center">
                              <IconMail className="w-5 h-5 text-blue-500" />
                           </div>
                           <div>
                              <p className="text-sm text-gray-500">E-posta</p>
                              <p className="text-sm font-medium text-gray-700">{contact.email}</p>
                           </div>
                        </div>

                        <div className="flex items-center gap-3 p-3 rounded-lg bg-gray-50/50">
                           <div className="w-10 h-10 rounded-lg bg-green-500/10 flex items-center justify-center">
                              <IconPhone className="w-5 h-5 text-green-500" />
                           </div>
                           <div>
                              <p className="text-sm text-gray-500">Telefon</p>
                              <p className="text-sm font-medium text-gray-700">{contact.phone}</p>
                           </div>
                        </div>
                     </div>

                     <div className="flex justify-end mt-4 pt-4 border-t border-gray-100">
                        <Button
                           size="sm"
                           color="primary"
                           variant="flat"
                           onClick={() => handleCreateOffer(contact)}
                           startContent={<IconFilePlus className="w-4 h-4" />}
                        >
                           {user?.role === 'admin' ? 'Talep Oluştur' : 'Teklif Oluştur'}
                        </Button>
                     </div>
                  </div>
               </div>
            ))}
         </div>

         {filteredContacts.length === 0 && (
            <div className="text-center py-12">
               <div className="w-16 h-16 mx-auto mb-4 rounded-full bg-gray-100 flex items-center justify-center">
                  <IconSearch className="w-8 h-8 text-gray-400" />
               </div>
               {searchTerm ? (
                  <>
                     <h3 className="text-lg font-semibold text-gray-800">Sonuç Bulunamadı</h3>
                     <p className="text-gray-500 mt-1">"{searchTerm}" ile eşleşen kişi bulunamadı.</p>
                  </>
               ) : (
                  <>
                     <h3 className="text-lg font-semibold text-gray-800">Henüz kişi bulunmuyor</h3>
                     <p className="text-gray-500 mt-1">Bu firmaya ait kişi kaydı bulunmamaktadır.</p>
                     <Button
                        color="primary"
                        className="mt-4"
                        startContent={<IconPlus className="w-4 h-4" />}
                        onClick={onAdd}
                     >
                        Yeni Kişi Ekle
                     </Button>
                  </>
               )}
            </div>
         )}

         {createRequestModal && <CreateRequestModal />}
      </div>
   );
};

export default ContactCard;
