export const offerAdminStatus = (row: any) => {
   let status = row

   let object: any = {}

   if (!status.is_sent_offer_mail && status.is_assigned) {
      object = {
         status: 'Atandı, Teklif gönderilmedi',
         color: 'secondary'
      }
   } else if (status.is_sent_offer_mail && status.is_assigned) {
      object = {
         status: 'Atandı, Teklif gönderildi',
         color: 'success'
      }
   } else if (!status.is_sent_offer_mail && !status.is_assigned) {
      object = {
         status: 'Kişi ataması bekleniyor',
         color: 'danger'
      }
   }

   return object
}


export const convertCurrency = (currencies: any, amount: number, fromCurrency: string, toCurrency: string) => {
   if (fromCurrency === toCurrency) return amount;

   const rateKey = `${fromCurrency}-${toCurrency}`;
   const rate = currencies.find((c: any) => c.currency === rateKey)?.rate || 1;
   return amount * rate;
};