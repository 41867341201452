import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { IconAlertTriangle, IconInfoCircle } from '@tabler/icons-react';

interface RichTextEditorProps {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  height?: number;
  totalServices?: number;
}

const fontSizeArr = [
  '8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '36px', '48px', '60px'
];

const Size = ReactQuill.Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
ReactQuill.Quill.register(Size, true);

const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: fontSizeArr }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean']
    ]
  },
};

const formats = [
  'header',
  'size',
  'bold', 'italic', 'underline', 'strike',
  'color', 'background',
  'align',
  'list', 'bullet',
  'link', 'image'
];

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  value,
  onChange,
  placeholder = 'İçeriğinizi buraya yazın...',
  height = 300,
  totalServices = 0
}) => {
  const [isContentOverflow, setIsContentOverflow] = useState(false);
  const [currentHeight, setCurrentHeight] = useState(0);
  const MAX_HEIGHT = 600 - (totalServices * 25);
  const MAX_WIDTH = 800; // Maksimum genişlik
  const editorRef = useRef<any>(null);

  // Stil eklemeleri
  const styles = `
    .rich-text-editor .ql-container {
      max-height: ${MAX_HEIGHT}px;
      max-width: ${MAX_WIDTH}px;
      overflow-y: auto;
      overflow-x: hidden; /* Yatay scroll'u engelle */
    }

    .rich-text-editor .ql-editor {
      max-height: ${MAX_HEIGHT - 42}px;
      max-width: ${MAX_WIDTH}px;
      overflow-y: auto;
      overflow-x: hidden;
      word-wrap: break-word; /* Uzun kelimeleri kır */
      white-space: pre-wrap; /* Boşlukları ve satır sonlarını koru */
    }

    /* Tüm içeriğin genişliğini sınırla */
    .rich-text-editor .ql-editor p,
    .rich-text-editor .ql-editor h1,
    .rich-text-editor .ql-editor h2,
    .rich-text-editor .ql-editor h3,
    .rich-text-editor .ql-editor h4,
    .rich-text-editor .ql-editor h5,
    .rich-text-editor .ql-editor h6,
    .rich-text-editor .ql-editor ul,
    .rich-text-editor .ql-editor ol {
      max-width: 100%;
      word-wrap: break-word;
    }

    /* Tabloları responsive yap */
    .rich-text-editor .ql-editor table {
      max-width: 100%;
      table-layout: fixed;
      width: 100%;
    }

    .rich-text-editor .ql-editor td,
    .rich-text-editor .ql-editor th {
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    /* Resimleri responsive yap */
    .rich-text-editor .ql-editor img {
      max-width: 100%;
      height: auto;
    }
  `;

  if (typeof document !== 'undefined') {
    const styleSheet = document.createElement('style');
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }


  // İçerik yüksekliğini kontrol et
  const checkContentHeight = useCallback(() => {
    if (editorRef.current) {
      const editorElement = editorRef.current.getEditor().root;
      const contentHeight = editorElement.scrollHeight;
      setIsContentOverflow(contentHeight > MAX_HEIGHT);
      setCurrentHeight(contentHeight);
    }
  }, []);

  // İçerik değiştiğinde yükseklik kontrolü yap
  const handleChange = (content: string) => {
    onChange(content);
    setTimeout(checkContentHeight, 300); // İçerik render olduktan sonra kontrol et
  };

  // Component mount olduğunda ref'i ayarla
  useEffect(() => {
    checkContentHeight();
  }, [checkContentHeight]);

  return (
    <div className="rich-text-editor">
      {/* Uyarı Mesajı */}
      {isContentOverflow && (
        <div className="mb-2 p-3 bg-warning/10 border border-warning/20 rounded-lg">
          <div className="flex items-center gap-2 text-warning">
            <IconAlertTriangle size={18} />
            <span className="text-sm font-medium">
              İçerik PDF sınırlarını aşıyor! PDF çıktısında içerik taşabilir. şuanki yükseklik: {currentHeight} max yükseklik: {MAX_HEIGHT}
            </span>
          </div>
        </div>
      )}

      {/* Editor */}
      <div
        className={`relative ${isContentOverflow ? 'border-warning' : 'border-gray-200'}`}
        style={{ maxHeight: MAX_HEIGHT }}
      >
        <ReactQuill
          ref={editorRef}
          theme="snow"
          value={value}
          onChange={handleChange}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          style={{
            height,
            width: MAX_WIDTH,
            maxHeight: MAX_HEIGHT - 42, // Toolbar yüksekliğini çıkar
          }}
        />

        {/* Maksimum yükseklik göstergesi */}
        {isContentOverflow && (
          <div
            className="absolute bottom-0 left-0 right-0 h-1 bg-warning/20"
            title="Maksimum PDF yüksekliği"
          >
            <div className="h-full w-full bg-warning animate-pulse" />
          </div>
        )}
      </div>

      {/* Alt Bilgi */}
      {isContentOverflow && (
        <div className="mt-2 flex items-center justify-end gap-2 text-xs text-gray-500">
          <IconInfoCircle size={14} />
          <span>Önerilen maksimum yükseklik: 600px</span>
        </div>
      )}
    </div>
  );
};


export default RichTextEditor;