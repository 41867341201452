import { Form, Switch } from 'antd'
import React from 'react'
import { Input } from 'rizzui'

function CustomerForm() {
    return (
        <div>
            <Form.Item name="company_name" label="Şirket Adı" rules={[{ required: true, message: "Bu Alan Zorunludur" }]}>
                <Input />
            </Form.Item>
            <Form.Item name="name" label="Yetkili Kişi" rules={[{ required: true, message: "Bu Alan Zorunludur" }]}>
                <Input />
            </Form.Item>
            <Form.Item name="email" label="E-posta" rules={[
                { required: true, message: "Bu Alan Zorunludur" },
                { type: "email", message: "Geçerli bir e-posta girin" }
            ]}>
                <Input />
            </Form.Item>
            <Form.Item name="phone" label="Telefon" rules={[{ required: false, message: "Bu Alan Zorunludur" }]}>
                <Input />
            </Form.Item>

            {/* <Form.Item valuePropName='checked' name="status" label="Durum" rules={[{ required: false, message: "Bu Alan Zorunludur" }]}>
                <Switch />
            </Form.Item> */}
        </div>
    )
}

export default CustomerForm
